<template>
  <el-dialog
    title="证件识别"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    destroy-on-close
    custom-class="dialog-class"
    ><div class="dialog-content">
      <div class="dialog-content-tips">支持格式：jpg，单个文件不超过2MB。</div>
      <div class="dialog-content-upload">
        <div class="card-main" style="position: relative">
          <img
            class="card"
            v-if="fileData.face"
            :src="fileData.face"
            mode="aspectFill"
            @click="changFile2('face')"
          />
          <el-upload
            v-else
            class="upload-card"
            drag
            action="#"
            :show-file-list="false"
            :http-request="() => {}"
            :on-change="
              (file, fileList) => {
                changFile('face', file, fileList);
              }
            "
          >
            <img
              src="@/assets/images/policy/subject-face.png"
              style="width: 190px; height: 120px"
            />
            <div class="upload-card-tips">拖拽到此或点击上传</div>
          </el-upload>
          <div class="upload-card-types" slot="tip">证件主页正面</div>
          <i
            v-if="fileData.face"
            @click="deletImg('face')"
            class="el-icon-circle-close delet-close"
          ></i>
        </div>
        <div class="card-main">
          <img
            class="card"
            v-if="fileData.back"
            :src="fileData.back"
            mode="aspectFill"
            @click="changFile2('back')"
          />
          <el-upload
            v-else
            class="upload-card"
            drag
            action="#"
            :show-file-list="false"
            :http-request="() => {}"
            :on-change="
              (file, fileList) => {
                changFile('back', file, fileList);
              }
            "
          >
            <img
              src="@/assets/images/policy/subject-back.png"
              style="width: 190px; height: 120px"
            />
            <div class="upload-card-tips">拖拽到此或点击上传</div>
          </el-upload>
          <div class="upload-card-types" slot="tip">证件主页反面</div>
          <i
            v-if="fileData.back"
            @click="deletImg('back')"
            class="el-icon-circle-close delet-close"
          ></i>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { uploadFile } from "@/utils/index";
export default {
  name: "subjectDocumentRecognize",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(n) {
      this.fileData = { face: "", faceRaw: null, back: "", backRaw: null };
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileData: {
        face: "",
        faceRaw: null,
        back: "",
        backRaw: null,
      },
    };
  },
  methods: {
    changFile(type, file, fileList) {
      let url = URL.createObjectURL(file.raw);
      this.fileData[type] = url;
      this.fileData[`${type}Raw`] = file.raw;
    },
    changFile2(type) {
      uploadFile({ accept: "image/jpg" }).then((res) => {
        let url = URL.createObjectURL(res);
        this.fileData[type] = url;
        this.fileData[`${type}Raw`] = res;
      });
    },
    deletImg(type) {
      this.fileData[type] = "";
      this.fileData[`${type}Raw`] = null;
    },
    handleImport() {
      if (this.fileData.face && this.fileData.back) {
        this.$emit("submit", this.fileData);
        //this.dialogVisible = false;
      } else {
        this.$message.error("请补充照片！");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog-class {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-width: 640px;
  .el-dialog__header {
    background-color: #4278c9;
    .el-dialog__title {
      font-weight: bold;
      font-size: 18px;
      color: #ffffff;
    }
    .el-dialog__headerbtn {
      .el-dialog__close {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}
.dialog-content {
  &-tips {
    font-weight: 300;
    font-size: 12px;
    color: #333333;
  }
  &-upload {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
::v-deep .upload-card {
  .el-upload-dragger {
    background-color: #f3f8ff;
    width: 272px;
    height: 167px;
    border-radius: 8px 8px 8px 8px;
    border: 1px dashed #d1e3fe;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-types {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
  &-tips {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    margin-top: 8px;
  }
}
.card-main {
  position: relative;
  .delet-close {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
  }
  &:hover {
    .delet-close {
      display: block;
      cursor: pointer;
    }
  }
}
.card {
  width: 272px;
  height: 167px;
  background: #f3f8ff;
  border-radius: 8px 8px 8px 8px;
  border: 1px dashed #d1e3fe;
}
</style>
