import { render, staticRenderFns } from "./automaticCalculation.vue?vue&type=template&id=a0cfb456&scoped=true"
import script from "./automaticCalculation.vue?vue&type=script&lang=js"
export * from "./automaticCalculation.vue?vue&type=script&lang=js"
import style0 from "./automaticCalculation.vue?vue&type=style&index=0&id=a0cfb456&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0cfb456",
  null
  
)

export default component.exports