<template>
  <el-dialog width="30%"
    :title="info && info.dictValue"
    :visible.sync="dialogVisible"
    custom-class="caluse-dialog"
    append-to-body>
    <div v-html="info && info.stipulation"></div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    showDialog: Boolean,
    info: Object
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      }
    },
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
::v-deep .caluse-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    overflow: auto;
    padding: 24px;
  }
}
</style>
