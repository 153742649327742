<template>
  <div class="automatic">
    <div style="margin-right: 12px; font-size: 14px; color: #333333">
      是否自动计算保费
    </div>
    <el-radio-group v-model="modelValue" @change="handleChangeV" :disabled="disabled">
      <el-radio :label="true">是</el-radio>
      <el-radio :label="false">否</el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.modelValue = val;
      },
    },
    modelValue: {
      handler: function (val) {
      },
    },
  },
  data() {
    return {
      modelValue: true,
    };
  },
  methods: {
    handleChangeV(e) {
        if (e) {
          this.$confirm("此操作将自动计算保费, 并覆盖原保费金额, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$emit('change', e)
            })
            .catch(() => {
              this.modelValue = !e;
            });
        } else {
          this.$confirm("此操作将停止自动计算保费, 如需更改需手动输入, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$emit('change', e)
            })
            .catch(() => {
              this.modelValue = !e;
            });
        }
    }
  }
};
</script>

<style lang="scss" scoped>
.automatic {
  display: flex;
  align-items: center;
}
</style>