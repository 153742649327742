<template>
  <div class="el-collapse-box">
    <AssociationScroll>
      <AssociationScrollView title="基本信息" name="0">
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
        >
          <div class="container container-box">
            <el-form-item
              label="投保日期："
              v-if="
                permissions.insuranceOfDate &&
                !pageConfig.baseInfo.disShowItem.includes('insuranceOfDate')
              "
              prop="insuranceOfDate"
            >
              <el-date-picker
                disabled
                v-model="ruleForm.insuranceOfDate"
                type="date"
                format="yyyy / MM / dd"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="保单号："
              prop="policyNo"
              v-if="
                permissions.policyNo && pageConfig.baseInfo.includes('policyNo')
              "
            >
              <el-input
                disabled
                v-model="ruleForm.policyNo"
                maxlength="32"
                @input="input_text($event, ruleForm, 'policyNo')"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="投保类型："
              prop="applicantType"
              v-if="
                permissions.applicantType &&
                !pageConfig.baseInfo.disShowItem.includes('applicantType')
              "
            >
              <el-select
                disabled
                v-model="ruleForm.applicantType"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in applicantType"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
              <el-input
                disabled
                v-if="
                  ruleForm.applicantType === 0 || ruleForm.applicantType === '0'
                "
                v-model="ruleForm.applicantTypeName"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="保险期限："
              prop="startDate"
              ref="startDate"
              v-if="
                permissions.startDate &&
                !pageConfig.baseInfo.disShowItem.includes('startDate')
              "
            >
              <div class="merge-box">
                <el-date-picker
                  v-model="ruleForm.startDate"
                  type="date"
                  :disabled="!createCorrectionType.includes('3')"
                  @change="datePickerChange($event, 'startDate')"
                  placeholder="开始日期"
                  unlink-panels
                  format="yyyy / MM / dd"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd"
                  class="merge-date"
                  :clearable="false"
                ></el-date-picker>
                <span>至</span>
                <el-date-picker
                  v-model="ruleForm.endDate"
                  type="date"
                  @change="datePickerChange($event, 'endDate')"
                  unlink-panels
                  :disabled="!createCorrectionType.includes('3')"
                  format="yyyy / MM / dd"
                  placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  default-time="23:59:59"
                  class="merge-date"
                  :clearable="false"
                ></el-date-picker>
                <i class="el-icon-date" style="margin-right: 5px"></i>
              </div>
            </el-form-item>
            <el-form-item
              label="起止时间："
              prop="policyTime"
              v-if="
                permissions.endDate &&
                !pageConfig.baseInfo.disShowItem.includes('policyTime')
              "
            >
              <el-radio-group
                v-model="startType"
                @change="setDatePicker('startDate', ruleForm.startDate)"
              >
                <el-radio :label="1" disabled>12时-12时</el-radio>
                <el-radio :label="2" disabled>0时-24时</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="业务员："
              prop="salesmanId"
              ref="salesmanId"
              v-if="
                permissions.salesmanId &&
                !pageConfig.baseInfo.disShowItem.includes('salesmanId')
              "
              @change="handleChangeSalesman"
            >
              <EmployeeSelect
                disabled
                v-model="ruleForm.salesmanId"
                @change="changeValidateField('salesmanId')"
              />
            </el-form-item>
            <el-form-item
              label="录单员："
              ref="recorderId"
              prop="recorderId"
              v-if="
                permissions.recorder &&
                !pageConfig.baseInfo.disShowItem.includes('recorderId')
              "
            >
              <el-select
                disabled
                v-model="ruleForm.recorderId"
                filterable
                @change="handleChangeRecorder"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="渠道来源："
              ref="sourceChannel"
              prop="sourceChannel"
              v-if="
                permissions.fromSourceTypeName &&
                !pageConfig.baseInfo.disShowItem.includes('sourceChannel')
              "
            >
              <el-input
                v-model="ruleForm.sourceChannel"
                placeholder="请填写"
                maxlength="100"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="代理人："
              prop="agentName"
              v-if="
                permissions.agentId &&
                $route.meta.policyType != 3 &&
                !pageConfig.baseInfo.disShowItem.includes('agentName')
              "
            >
              <el-input
                disabled
                v-model="ruleForm.agentName"
                maxlength="100"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="代理人职业编码："
              prop="agentNo"
              ref="agentNo"
              v-if="
                permissions.agentNo &&
                !pageConfig.baseInfo.disShowItem.includes('agentNo')
              "
            >
              <el-input
                disabled
                v-model="ruleForm.agentNo"
                @input="input_text($event, ruleForm, 'agentNo')"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="投保门店："
              prop="applicantStore"
              v-if="
                permissions.applicantStore &&
                !pageConfig.baseInfo.disShowItem.includes('applicantStore')
              "
            >
              <el-select
                disabled
                v-model="ruleForm.applicantStore"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in applicantStore"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="上一年保单号："
              prop="lastPolicyNo"
              v-if="
                permissions.lastPolicyNo &&
                !pageConfig.baseInfo.disShowItem.includes('lastPolicyNo')
              "
            >
              <el-input disabled v-model="ruleForm.lastPolicyNo"></el-input>
            </el-form-item>
            <el-form-item
              label="投保方式："
              prop="applicantMode"
              v-if="
                permissions.applicantMode &&
                $route.meta.policyType === 1 &&
                !pageConfig.baseInfo.disShowItem.includes('applicantMode')
              "
            >
              <el-select
                disabled
                v-model="ruleForm.applicantMode"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in applicantMode"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="险种类型："
              prop="insuredCategory"
              required
              v-if="
                permissions.insuredCategory &&
                !pageConfig.baseInfo.disShowItem.includes('insuredCategory')
              "
            >
              <el-select
                disabled
                @change="
                  handleInsuredCategory(
                    $event,
                    FCXInsCategoryList,
                    'dictKey',
                    'dictValue',
                    ruleForm,
                    'insuredCategoryName'
                  )
                "
                v-model="ruleForm.insuredCategory"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in FCXInsCategoryList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
              <el-input
                v-if="
                  ruleForm.insuredCategory === 0 ||
                  ruleForm.insuredCategory === '0'
                "
                v-model="ruleForm.insuredCategoryName"
                placeholder="请填写"
                maxlength="64"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="总保费："
              required
              prop="totalPremium"
              v-if="
                permissions.totalPremium &&
                !pageConfig.baseInfo.disShowItem.includes('totalPremium')
              "
            >
              <el-input
                disabled
                v-model="ruleForm.totalPremium"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item
              required
              label="项目名称："
              ref="projectId"
              prop="projectId"
              v-if="permissions.projectName"
            >
              <!-- <el-select disabled
                v-model="ruleForm.projectId"
                filterable
                placeholder="请选择">
                <el-option v-for="item in projectNameOptions"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"></el-option>
              </el-select> -->
              <el-input disabled v-model="ruleForm.projectName" maxlength="100">
              </el-input>
            </el-form-item>
            <div
              class="policy-label-style"
              v-if="
                permissions.projectRemark &&
                !pageConfig.baseInfo.disShowItem.includes('projectRemark')
              "
            >
              项目备注：
            </div>
            <el-input
              disabled
              type="textarea"
              v-if="
                permissions.projectRemark &&
                !pageConfig.baseInfo.disShowItem.includes('projectRemark')
              "
              v-model="ruleForm.projectRemark"
              maxlength="256"
              :rows="6"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </el-form>
      </AssociationScrollView>
      <AssociationScrollView
        title="投保人"
        name="1"
        v-if="permissions.policyHolderList"
      >
        <template v-if="createCorrectionType.includes('1')" slot="head-right">
          <el-button
            type="success"
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="
              handleAddContent('policyHolderList', {
                cardEnd: '',
                cardNo: '',
                cardStart: '',
                cardType: 1,
                city: '',
                contactEmail: '',
                contactName: '',
                contactPhone: '',
                county: '',
                detailAddress: '',
                playersName: '',
                playersType: 1,
                province: '',
                birthday: '',
                sex: true,
              })
            "
            >添加</el-button
          >
        </template>
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm1"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
          :disabled="!createCorrectionType.includes('1')"
        >
          <div class="container">
            <div
              v-for="(item, index) in ruleForm.policyHolderList"
              :key="index"
              :ref="'policyHolderList' + index"
              class="container-box"
            >
              <div class="personnel_repetition" v-if="item.check">
                <i
                  class="el-icon-warning"
                  style="
                    color: rgba(252, 184, 24, 1);
                    margin-right: 8px;
                    font-size: 24px;
                  "
                ></i>
                <span>重复提示：此投保人（{{ item.cardNo }}）已重复存在！</span>
                <span
                  class="personnel_repetition_details"
                  @click="lookRepetition(item, 2)"
                  >查看详情</span
                >
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 24px;
                "
              >
                <el-radio-group v-model="item.playersType">
                  <el-radio :label="1"
                    >团体（企业、社会团体及其他组织)</el-radio
                  >
                  <el-radio :label="2">个人</el-radio>
                </el-radio-group>
                <el-button
                  plain
                  v-if="index"
                  @click="handleDelContent('policyHolderList', index)"
                  icon="el-icon-circle-close"
                  style="color: rgba(201, 66, 66, 1)"
                  >删除</el-button
                >
              </div>
              <el-form-item
                label="投保人："
                prop="playersName"
                v-if="
                  !pageConfig.policyHolder.disShowItem.includes('playersName')
                "
              >
                <template slot="label">
                  <div class="certificate-box">
                    <span> <span style="color: #f56c6c">*</span>投保人： </span>
                  </div>
                </template>
                <el-tooltip
                  effect="dark"
                  popper-class="popper-customization"
                  :content="item.playersName"
                  :hide-after="500000"
                  placement="top"
                  :disabled="!item.playersName.length"
                >
                  <el-autocomplete
                    placeholder="请填写投保人"
                    v-model="item.playersName"
                    @input="changeQuerySearch($event, 2, item)"
                    class="input-customization"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="false"
                    @select="handleSelect($event, 'policyHolderList', index)"
                  >
                    <el-popover
                      slot="suffix"
                      placement="right"
                      width="400"
                      trigger="click"
                    >
                      <div v-if="!findPolicyInsuredList.length">--</div>
                      <div
                        v-else
                        v-for="(val, ind) in findPolicyInsuredList"
                        :key="ind"
                        style="padding: 8px; cursor: pointer"
                        @click="
                          handleSynchronization('policyHolderList', index, val)
                        "
                      >
                        {{ val.playersName }}
                      </div>
                    </el-popover>
                  </el-autocomplete>
                </el-tooltip>
              </el-form-item>
              <el-form-item
                label="证件类型："
                prop="cardType"
                v-if="!pageConfig.policyHolder.disShowItem.includes('cardType')"
                class="certificate-label"
              >
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <span style="color: #f56c6c">*</span> 证件类型：
                    </span>
                    <div
                      class="certificate-box-right"
                      v-if="item.playersType == 1 || item.cardType == 1"
                    >
                      <div
                        class="example"
                        @click="
                          (ExampleTypeName =
                            item.playersType == 1 ? 'business' : 'identity'),
                            (isExampleDialog = true)
                        "
                      >
                        示例
                      </div>
                      <div
                        class="label-button"
                        @click="
                          handleIdentify(
                            item.playersType,
                            'policyHolderList',
                            index
                          ),
                            (AssignmentObjType = 2)
                        "
                      >
                        证件识别
                      </div>
                    </div>
                  </div>
                </template>
                <el-select
                  v-if="item.playersType == 2"
                  v-model="item.cardType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="dictItem in identCardType"
                    :key="dictItem.dictKey"
                    :label="dictItem.dictValue"
                    :value="dictItem.dictKey"
                  ></el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="item.cardType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="统一社会信用代码" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="证件号码："
                prop="cardNo"
                v-if="!pageConfig.policyHolder.disShowItem.includes('cardNo')"
              >
                <template slot="label">
                  <span style="color: #f56c6c">*</span>证件号码：
                </template>
                <el-input
                  v-model="item.cardNo"
                  maxlength="18"
                  @blur="handleWeightCheck(item, 2)"
                  @input="input_text($event, item, 'cardNo')"
                ></el-input>
              </el-form-item>

              <template v-if="$route.meta.policyType == 1">
                <el-form-item
                  v-if="
                    item.playersType == 2 &&
                    !pageConfig.policyHolder.disShowItem.includes('birthday')
                  "
                  label="出生日期："
                  prop="birthday"
                >
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    v-model="item.birthday"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  v-if="
                    item.playersType == 2 &&
                    !pageConfig.policyHolder.disShowItem.includes('sex')
                  "
                  label="性别："
                  prop="sex"
                >
                  <el-radio-group
                    v-model="item.sex"
                    style="margin-bottom: 24px"
                  >
                    <el-radio :label="true">男</el-radio>
                    <el-radio :label="false">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="所在区域："
                  prop="InsuredProvince"
                  v-if="
                    !pageConfig.policyHolder.disShowItem.includes(
                      'InsuredProvince'
                    )
                  "
                >
                  <template slot="label">
                    <span style="color: #f56c6c">*</span>所在区域：
                  </template>
                  <v-distpicker
                    class="distpicker-all-box"
                    :province="item.province"
                    :city="item.city"
                    hide-area
                    @selected="onSelected($event, 'policyHolderList', index)"
                    :disabled="!createCorrectionType.includes('1')"
                  ></v-distpicker>
                </el-form-item>
                <el-form-item
                  label="证件有效期："
                  prop="cardStart"
                  class="certificate-label"
                  v-if="
                    !pageConfig.policyHolder.disShowItem.includes('cardStart')
                  "
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <!-- <span style="color: #f56c6c">*</span> -->
                        证件有效期：
                      </span>
                      <div class="certificate-box-right">
                        <el-checkbox
                          v-model="item.checked"
                          :disabled="!item.cardStart"
                          @change="handleLongTime($event, item)"
                          >长期</el-checkbox
                        >
                      </div>
                    </div>
                  </template>
                  <div class="merge-box">
                    <i class="el-icon-date" style="color: #c0c4cc"></i>
                    <el-date-picker
                      v-model="item.cardStart"
                      type="date"
                      placeholder="开始日期"
                      unlink-panels
                      format="yyyy / MM / dd"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd"
                      @change="
                        handleChangeEndTime(
                          $event,
                          index,
                          'policyHolderList',
                          0
                        )
                      "
                      class="merge-date"
                    ></el-date-picker>
                    <span>至</span>
                    <el-date-picker
                      v-model="item.cardEnd"
                      type="date"
                      unlink-panels
                      format="yyyy / MM / dd"
                      placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      default-time="23:59:59"
                      class="merge-date"
                      @change="
                        handleChangeEndTime(
                          $event,
                          index,
                          'policyHolderList',
                          1
                        )
                      "
                      v-if="!item.checked"
                    ></el-date-picker>
                    <div class="merge-date" v-else>长期</div>
                  </div>
                </el-form-item>
                <el-form-item
                  label="联系人姓名："
                  prop="contactName"
                  v-if="
                    !pageConfig.policyHolder.disShowItem.includes('contactName')
                  "
                >
                  <template slot="label">
                    <span style="color: #f56c6c">*</span>联系人姓名：
                  </template>
                  <el-input
                    v-model="item.contactName"
                    maxlength="64"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="联系电话："
                  prop="contactPhone"
                  v-if="
                    !pageConfig.policyHolder.disShowItem.includes(
                      'contactPhone'
                    )
                  "
                >
                  <template slot="label">
                    <span style="color: #f56c6c">*</span>联系电话：
                  </template>
                  <el-input
                    v-model="item.contactPhone"
                    maxlength="11"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="联系邮箱："
                  prop="contactEmail"
                  v-if="
                    !pageConfig.policyHolder.disShowItem.includes(
                      'contactEmail'
                    )
                  "
                >
                  <el-input
                    v-model="item.contactEmail"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </template>
            </div>
          </div>
        </el-form>
      </AssociationScrollView>
      <AssociationScrollView
        title="被保险人"
        name="2"
        v-if="permissions.policyInsuredList"
      >
        <template v-if="createCorrectionType.includes('1')" slot="head-right">
          <el-button
            type="success"
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="
              handleAddContent('policyInsuredList', {
                cardEnd: '',
                cardNo: '',
                cardStart: '',
                cardType: 1,
                city: '',
                contactEmail: '',
                contactName: '',
                contactPhone: '',
                county: '',
                detailAddress: '',
                playersName: '',
                playersType: 1,
                province: '',
                birthday: '',
                sex: true,
              })
            "
            >添加</el-button
          >
        </template>
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm1"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
          :disabled="!createCorrectionType.includes('1')"
        >
          <div class="container">
            <div
              v-for="(item, index) in ruleForm.policyInsuredList"
              :key="index"
              :ref="'policyInsuredList' + index"
              class="container-box"
            >
              <div class="personnel_repetition" v-if="item.check">
                <i
                  class="el-icon-warning"
                  style="
                    color: rgba(252, 184, 24, 1);
                    margin-right: 8px;
                    font-size: 24px;
                  "
                ></i>
                <span
                  >重复提示：此被保险人（{{ item.cardNo }}）已重复存在！</span
                >
                <span
                  class="personnel_repetition_details"
                  @click="lookRepetition(item, 1)"
                  >查看详情</span
                >
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 24px;
                "
              >
                <el-radio-group v-model="item.playersType">
                  <el-radio :label="1"
                    >团体（企业、社会团体及其他组织)</el-radio
                  >
                  <el-radio :label="2">个人</el-radio>
                </el-radio-group>
                <el-button
                  plain
                  v-if="index"
                  icon="el-icon-circle-close"
                  style="color: rgba(201, 66, 66, 1)"
                  @click="handleDelContent('policyInsuredList', index)"
                  >删除</el-button
                >
              </div>
              <el-form-item
                label="被保险人："
                prop="playersName"
                v-if="
                  !pageConfig.policyInsured.disShowItem.includes('playersName')
                "
              >
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <span style="color: #f56c6c">*</span>被保险人：
                    </span>
                  </div>
                </template>
                <el-tooltip
                  effect="dark"
                  popper-class="popper-customization"
                  :content="item.playersName"
                  :hide-after="500000"
                  placement="top"
                  :disabled="!item.playersName.length"
                >
                  <el-autocomplete
                    placeholder="请填写被保险人"
                    v-model="item.playersName"
                    @input="changeQuerySearch($event, 1, item)"
                    class="input-customization"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="false"
                    @select="handleSelect($event, 'policyInsuredList', index)"
                  >
                    <el-popover
                      slot="suffix"
                      placement="right"
                      width="400"
                      trigger="click"
                    >
                      <div v-if="!findPolicyInsuredList.length">--</div>
                      <div
                        v-else
                        v-for="(val, ind) in findPolicyInsuredList"
                        :key="ind"
                        style="padding: 8px; cursor: pointer"
                        @click="
                          handleSynchronization('policyInsuredList', index, val)
                        "
                      >
                        {{ val.playersName }}
                      </div>
                      <el-button slot="reference" type="text"
                        >同投保人</el-button
                      >
                    </el-popover></el-autocomplete
                  >
                </el-tooltip>
              </el-form-item>
              <el-form-item
                label="证件类型："
                prop="cardType"
                class="certificate-label"
                v-if="
                  !pageConfig.policyInsured.disShowItem.includes('cardType')
                "
              >
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <span style="color: #f56c6c">*</span>证件类型：
                    </span>
                    <div
                      class="certificate-box-right"
                      v-if="item.playersType == 1 || item.cardType == 1"
                    >
                      <div
                        class="example"
                        @click="
                          (ExampleTypeName =
                            item.playersType == 1 ? 'business' : 'identity'),
                            (isExampleDialog = true)
                        "
                      >
                        示例
                      </div>
                      <div
                        class="label-button"
                        @click="
                          handleIdentify(
                            item.playersType,
                            'policyInsuredList',
                            index
                          ),
                            (AssignmentObjType = 1)
                        "
                      >
                        证件识别
                      </div>
                    </div>
                  </div>
                </template>
                <el-select
                  v-if="item.playersType == 2"
                  v-model="item.cardType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="dictItem in identCardType"
                    :key="dictItem.dictKey"
                    :label="dictItem.dictValue"
                    :value="dictItem.dictKey"
                  ></el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="item.cardType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="统一社会信用代码" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="证件号码："
                prop="cardNo"
                v-if="!pageConfig.policyInsured.disShowItem.includes('cardNo')"
              >
                <template slot="label">
                  <span style="color: #f56c6c">*</span>证件号码：
                </template>
                <el-input
                  v-model="item.cardNo"
                  @blur="handleWeightCheck(item, 1)"
                  maxlength="18"
                  @input="input_text($event, item, 'cardNo')"
                ></el-input>
              </el-form-item>
              <template v-if="$route.meta.policyType == 3">
                <el-form-item
                  label="岗位职责："
                  v-if="item.playersType == 2"
                  prop="postResponsibilities"
                >
                  <template slot="label">岗位职责： </template>
                  <el-input
                    v-model="item.postResponsibilities"
                    placeholder="请填写"
                    maxlength="64"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-if="item.playersType == 2"
                  label="是否购买社保："
                  prop="hasSb"
                >
                  <el-radio-group
                    v-model="item.hasSb"
                    style="margin-bottom: 24px"
                  >
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="职业类别："
                  prop="cardType"
                  v-if="item.playersType == 2"
                  class="certificate-label"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>职业类别： </span>
                    </div>
                  </template>
                  <el-select
                    v-model="item.jobCategory"
                    @change="
                      handleInsuredCategory(
                        $event,
                        JobCategoryList,
                        'dictKey',
                        'dictValue',
                        item,
                        'jobCategoryDesc'
                      )
                    "
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="dictItem in JobCategoryList"
                      :key="dictItem.dictKey"
                      :label="dictItem.dictValue"
                      :value="dictItem.dictKey"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-if="$route.meta.policyType == 1">
                <el-form-item
                  v-if="
                    item.playersType == 2 &&
                    !pageConfig.policyInsured.disShowItem.includes('birthday')
                  "
                  label="出生日期："
                  prop="birthday"
                >
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    v-model="item.birthday"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  v-if="
                    item.playersType == 2 &&
                    !pageConfig.policyInsured.disShowItem.includes('sex')
                  "
                  label="性别："
                  prop="sex"
                >
                  <el-radio-group
                    v-model="item.sex"
                    style="margin-bottom: 24px"
                  >
                    <el-radio :label="true">男</el-radio>
                    <el-radio :label="false">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="所在区域："
                  prop="InsuredProvince"
                  v-if="
                    !pageConfig.policyInsured.disShowItem.includes(
                      'InsuredProvince'
                    )
                  "
                >
                  <template slot="label">
                    <span style="color: #f56c6c">*</span>所在区域：
                  </template>
                  <v-distpicker
                    class="distpicker-all-box"
                    :province="item.province"
                    :city="item.city"
                    hide-area
                    @selected="onSelected($event, 'policyInsuredList', index)"
                    :disabled="!createCorrectionType.includes('1')"
                  ></v-distpicker>
                </el-form-item>
                <el-form-item
                  label="证件有效期："
                  prop="cardStart"
                  class="certificate-label"
                  v-if="
                    !pageConfig.policyInsured.disShowItem.includes('cardStart')
                  "
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <!-- <span style="color: #f56c6c">*</span> -->
                        证件有效期：
                      </span>
                      <div class="certificate-box-right">
                        <el-checkbox
                          v-model="item.checked"
                          :disabled="!item.cardStart"
                          @change="handleLongTime($event, item)"
                          >长期</el-checkbox
                        >
                      </div>
                    </div>
                  </template>
                  <div class="merge-box">
                    <i class="el-icon-date" style="color: #c0c4cc"></i>
                    <el-date-picker
                      v-model="item.cardStart"
                      type="date"
                      placeholder="开始日期"
                      unlink-panels
                      format="yyyy / MM / dd"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd"
                      @change="
                        handleChangeEndTime(
                          $event,
                          index,
                          'policyInsuredList',
                          0
                        )
                      "
                      class="merge-date"
                    ></el-date-picker>
                    <span>至</span>
                    <el-date-picker
                      v-model="item.cardEnd"
                      type="date"
                      unlink-panels
                      format="yyyy / MM / dd"
                      placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      default-time="23:59:59"
                      class="merge-date"
                      @change="
                        handleChangeEndTime(
                          $event,
                          index,
                          'policyInsuredList',
                          1
                        )
                      "
                      v-if="!item.checked"
                    ></el-date-picker>
                    <div class="merge-date" v-else>长期</div>
                  </div>
                </el-form-item>
                <el-form-item
                  label="联系人姓名："
                  prop="contactName"
                  v-if="
                    !pageConfig.policyInsured.disShowItem.includes(
                      'contactName'
                    )
                  "
                >
                  <template slot="label">
                    <span style="color: #f56c6c">*</span>联系人姓名：
                  </template>
                  <el-input
                    v-model="item.contactName"
                    maxlength="64"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="联系电话："
                  prop="contactPhone"
                  v-if="
                    !pageConfig.policyInsured.disShowItem.includes(
                      'contactPhone'
                    )
                  "
                >
                  <template slot="label">
                    <span style="color: #f56c6c">*</span>联系电话：
                  </template>
                  <el-input
                    v-model="item.contactPhone"
                    maxlength="11"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="联系邮箱："
                  prop="contactEmail"
                  v-if="
                    !pageConfig.policyInsured.disShowItem.includes(
                      'contactEmail'
                    )
                  "
                >
                  <el-input
                    v-model="item.contactEmail"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </template>
            </div>
          </div>
        </el-form>
      </AssociationScrollView>
      <AssociationScrollView
        title="受益人"
        name="3"
        v-if="permissions.beneficiaryList && pageConfig.showBeneficiary.show"
      >
        <template v-if="createCorrectionType.includes('1')" slot="head-right">
          <el-button
            type="success"
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="
              handleAddContent('beneficiaryList', {
                cardEnd: '',
                cardNo: '',
                cardStart: '',
                cardType: 1,
                city: '',
                contactEmail: '',
                contactName: '',
                contactPhone: '',
                county: '',
                detailAddress: '',
                playersName: '',
                playersType: 1,
                province: '',
                birthday: '',
                sex: true,
              })
            "
            >添加</el-button
          >
        </template>
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm1"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
          :disabled="!createCorrectionType.includes('1')"
        >
          <div class="container">
            <div
              v-for="(item, index) in ruleForm.beneficiaryList"
              class="container-box"
              :ref="'beneficiaryList' + index"
              :key="index"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 24px;
                "
              >
                <el-radio-group v-model="item.playersType">
                  <el-radio :label="1"
                    >团体（企业、社会团体及其他组织)</el-radio
                  >
                  <el-radio :label="2">个人</el-radio>
                </el-radio-group>
                <el-button
                  plain
                  v-if="index"
                  @click="handleDelContent('beneficiaryList', index)"
                  icon="el-icon-circle-close"
                  style="color: rgba(201, 66, 66, 1)"
                  >删除</el-button
                >
              </div>
              <el-form-item label="收益人：" prop="playersName">
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <span
                        style="color: #f56c6c"
                        v-if="$route.meta.policyType != 2"
                        >*</span
                      >受益人：
                    </span>
                  </div>
                </template>
                <el-tooltip
                  effect="dark"
                  popper-class="popper-customization"
                  :content="item.playersName"
                  :hide-after="500000"
                  placement="top"
                  :disabled="!item.playersName.length"
                >
                  <el-autocomplete
                    placeholder="请填写受益人"
                    v-model="item.playersName"
                    @input="changeQuerySearch($event, 3, item)"
                    class="input-customization"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="false"
                    @select="handleSelect($event, 'beneficiaryList', index)"
                  >
                    <el-popover
                      slot="suffix"
                      placement="right"
                      width="400"
                      trigger="click"
                    >
                      <div>
                        <div>
                          <div>投保人</div>
                          <div v-if="!findPolicyInsuredList?.length">--</div>
                          <div
                            v-else
                            v-for="(val, ind) in findPolicyInsuredList"
                            :key="ind"
                            style="padding: 8px; cursor: pointer"
                            @click="
                              handleSynchronization(
                                'beneficiaryList',
                                index,
                                val
                              )
                            "
                          >
                            {{ val.playersName }}
                          </div>
                        </div>
                        <div>
                          <div>被保险人</div>
                          <div v-if="!findInsuredList?.length">--</div>
                          <div
                            v-else
                            v-for="(val, ind) in findInsuredList"
                            :key="ind"
                            style="padding: 8px; cursor: pointer"
                            @click="
                              handleSynchronization(
                                'beneficiaryList',
                                index,
                                val
                              )
                            "
                          >
                            {{ val.playersName }}
                          </div>
                        </div>
                      </div>
                      <el-button slot="reference" type="text"
                        >同投保人/被保险人</el-button
                      >
                    </el-popover>
                  </el-autocomplete>
                </el-tooltip>
              </el-form-item>
              <el-form-item
                label="证件类型："
                prop="cardType"
                class="certificate-label"
              >
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <span
                        style="color: #f56c6c"
                        v-if="$route.meta.policyType != 2"
                        >*</span
                      >证件类型：
                    </span>
                    <div
                      class="certificate-box-right"
                      v-if="item.playersType == 1 || item.cardType == 1"
                    >
                      <div
                        class="example"
                        @click="
                          (ExampleTypeName =
                            item.playersType == 1 ? 'business' : 'identity'),
                            (isExampleDialog = true)
                        "
                      >
                        示例
                      </div>
                      <div
                        class="label-button"
                        @click="
                          handleIdentify(
                            item.playersType,
                            'beneficiaryList',
                            index
                          ),
                            (AssignmentObjType = null)
                        "
                      >
                        证件识别
                      </div>
                    </div>
                  </div>
                </template>
                <el-select
                  v-if="item.playersType == 2"
                  v-model="item.cardType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="dictItem in identCardType"
                    :key="dictItem.dictKey"
                    :label="dictItem.dictValue"
                    :value="dictItem.dictKey"
                  ></el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="item.cardType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="统一社会信用代码" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="证件号码：" prop="cardNo">
                <template slot="label">
                  <span
                    style="color: #f56c6c"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >证件号码：
                </template>
                <el-input
                  v-model="item.cardNo"
                  maxlength="18"
                  @input="input_text($event, item, 'cardNo')"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="item.playersType == 2"
                label="出生日期："
                prop="birthday"
              >
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  v-model="item.birthday"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                v-if="item.playersType == 2"
                label="性别："
                prop="sex"
              >
                <el-radio-group v-model="item.sex" style="margin-bottom: 24px">
                  <el-radio :label="true">男</el-radio>
                  <el-radio :label="false">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="所在区域：" prop="InsuredProvince">
                <template slot="label">
                  <span
                    style="color: #f56c6c"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >所在区域：
                </template>
                <v-distpicker
                  class="distpicker-all-box"
                  :province="item.province"
                  :city="item.city"
                  hide-area
                  @selected="onSelected($event, 'beneficiaryList', index)"
                  :disabled="!createCorrectionType.includes('1')"
                ></v-distpicker>
              </el-form-item>
              <el-form-item
                label="证件有效期："
                prop="cardStart"
                class="certificate-label"
              >
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <!-- <span style="color: #f56c6c">*</span> -->
                      证件有效期：
                    </span>
                    <div class="certificate-box-right">
                      <el-checkbox
                        v-model="item.checked"
                        :disabled="!item.cardStart"
                        @change="handleLongTime($event, item)"
                        >长期</el-checkbox
                      >
                    </div>
                  </div>
                </template>
                <div class="merge-box">
                  <i class="el-icon-date" style="color: #c0c4cc"></i>
                  <el-date-picker
                    v-model="item.cardStart"
                    type="date"
                    placeholder="开始日期"
                    unlink-panels
                    format="yyyy / MM / dd"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd"
                    @change="
                      handleChangeEndTime($event, index, 'beneficiaryList', 0)
                    "
                    class="merge-date"
                  ></el-date-picker>
                  <span>至</span>
                  <el-date-picker
                    v-model="item.cardEnd"
                    type="date"
                    unlink-panels
                    format="yyyy / MM / dd"
                    placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    default-time="23:59:59"
                    class="merge-date"
                    @change="
                      handleChangeEndTime($event, index, 'beneficiaryList', 1)
                    "
                    v-if="!item.checked"
                  ></el-date-picker>
                  <div class="merge-date" v-else>长期</div>
                </div>
              </el-form-item>
              <el-form-item label="联系人姓名：" prop="contactName">
                <template slot="label">
                  <span
                    style="color: #f56c6c"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >联系人姓名：
                </template>
                <el-input v-model="item.contactName" maxlength="64"></el-input>
              </el-form-item>
              <el-form-item label="联系电话：" prop="contactPhone">
                <template slot="label">
                  <span
                    style="color: #f56c6c"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >联系电话：
                </template>
                <el-input v-model="item.contactPhone" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item
                label="联系邮箱："
                prop="contactEmail"
                v-if="
                  !pageConfig.showBeneficiary.disShowItem.includes(
                    'contactEmail'
                  )
                "
              >
                <el-input
                  v-model="item.contactEmail"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </AssociationScrollView>
      <AssociationScrollView
        title="承保公司"
        name="4"
        v-if="permissions.acceptCompanyList"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
          disabled
        >
          <div class="container">
            <div
              v-for="(item, index) in ruleForm.acceptCompanyList"
              :key="index"
              :ref="'acceptCompanyList' + index"
              class="container-box"
            >
              <el-form-item label="保险公司：" prop="companyName">
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <span style="color: #f56c6c">*</span>保险公司：
                    </span>
                  </div>
                </template>
                <el-select
                  v-model="item.companyName"
                  filterable
                  @change="handleChangeCompany($event, index)"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="dictItem in insuranceCompany"
                    :key="dictItem.dictKey"
                    :label="dictItem.dictValue"
                    :value="dictItem.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分支机构：" prop="branch">
                <template slot="label">
                  <div class="certificate-box">
                    <span>
                      <span style="color: #f56c6c">*</span>分支机构：
                    </span>
                  </div>
                </template>
                <el-select v-model="item.branch" placeholder="请选择">
                  <el-option
                    v-for="(dictItem, indexx) in item.insuranceBranch"
                    :key="indexx"
                    :label="dictItem"
                    :value="dictItem"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="承保比例：" prop="acceptRate">
                <el-input
                  v-model="item.acceptRate"
                  @input="input_num($event, item, 'acceptRate', 100)"
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
              <div
                class="el-radio"
                style="display: block; margin-bottom: 18px"
                @click="handleChangeProtagonist(index)"
              >
                <span
                  :class="{
                    'el-radio__input': true,
                    'is-checked': item.acceptPrimary,
                  }"
                >
                  <span class="el-radio__inner"></span>
                </span>
                <span class="el-radio__label">主承保公司</span>
              </div>
            </div>
          </div>
        </el-form>
      </AssociationScrollView>
      <AssociationScrollView
        title="标的信息"
        name="5"
        v-if="
          permissions.policySubjectList &&
          pageConfig.showSubject &&
          $route.meta.policyType != 3
        "
      >
        <template slot="head-right">
          <el-button
            type="success"
            v-if="!ruleForm.isSingle && createCorrectionType.includes('2')"
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="isSubjectDialog = true"
            >导入</el-button
          >
          <el-button
            type="success"
            v-if="
              !ruleForm.isSingle &&
              ruleForm.policySubjectList.length &&
              ruleForm.id &&
              createCorrectionType.includes('2') &&
              false
            "
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="handleDownloadSubjectList"
            >导出</el-button
          >
          <el-button
            type="success"
            icon="el-icon-circle-plus-outline"
            size="small"
            v-if="ruleForm.isSingle && createCorrectionType.includes('2')"
            @click="
              handleAddContent('policySubjectList', {
                address: '',
                addressLonlat: '',
                agency: '',
                brand: '',
                brandName: '',
                curbWeight: '',
                deviceType: '',
                id: '-1',
                deviceTypeName: '',
                engineNo: '',
                equipmentType: '',
                frameNo: '',
                itemNo: '',
                mainInsuranceAmount: '',
                origin: 1,
                premium: '',
                productionDate: '',
                purchasePrice: '',
                workTime: '',
                yearlyDepreciation: '',
                vehicleAccredit: false,
              })
            "
            >添加</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-document-copy"
            size="small"
            v-if="ruleForm.isSingle && createCorrectionType.includes('2')"
            @click="
              handleAddContent(
                'policySubjectList',
                ruleForm.policySubjectList[0]
              )
            "
            >复制</el-button
          >
        </template>
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm2"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
          :disabled="!createCorrectionType.includes('2')"
        >
          <div class="container-box">
            <div>
              <el-radio-group
                v-model="ruleForm.isSingle"
                style="margin-bottom: 24px"
                @change="handleChangeSubject"
              >
                <el-radio :label="true">单一标的</el-radio>
                <el-radio :label="false">多个标的</el-radio>
              </el-radio-group>
            </div>
            <div v-if="ruleForm.isSingle">
              <div
                class="list-item"
                v-for="(item, index) in ruleForm.policySubjectList"
                :ref="'policySubjectList' + index"
                :key="index"
              >
                <div class="right-btn" v-if="createCorrectionType == 2">
                  <el-button
                    @click="
                      subjectRecognizeObj = item;
                      subjectRecognizeShow = true;
                    "
                    class="recognize-btn"
                  >
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/images/policy/rec-sub-icon.png"
                        style="width: 19px; height: 19px"
                      />
                      <div style="margin-left: 4px">证件识别</div>
                    </div>
                  </el-button>
                  <el-button
                    type="danger"
                    plain
                    v-if="index != 0"
                    icon="el-icon-circle-close"
                    style="color: rgba(201, 66, 66, 1)"
                    @click="handleDelContent('policySubjectList', index)"
                    >删除</el-button
                  >
                </div>
                <el-form-item
                  label="设备品牌："
                  v-if="!pageConfig.policySubject.disShowItem.includes('brand')"
                  prop="brand"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>设备品牌：
                      </span>
                    </div>
                  </template>
                  <el-select
                    v-model="item.brand"
                    filterable
                    @change="handleChangBrand($event, item)"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="dictItem in vehicleBrand"
                      :key="dictItem.dictKey"
                      :label="dictItem.dictValue"
                      :value="dictItem.dictKey"
                    ></el-option>
                  </el-select>
                  <el-input
                    v-if="item.brand === 0 || item.brand === '0'"
                    v-model="item.brandName"
                    placeholder="请填写"
                    maxlength="64"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="设备类型："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('deviceType')
                  "
                  prop="deviceType"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>设备类型：
                      </span>
                    </div>
                  </template>
                  <el-select
                    v-model="item.deviceType"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="dictItem in vehicleType"
                      :key="dictItem.dictKey"
                      :label="dictItem.dictValue"
                      :value="dictItem.dictKey"
                    ></el-option>
                  </el-select>
                  <el-input
                    v-if="item.deviceType === 0 || item.deviceType === '0'"
                    v-model="item.deviceTypeName"
                    placeholder="请填写"
                    maxlength="64"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="设备型号："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'equipmentType'
                    )
                  "
                  prop="equipmentType"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>设备型号：
                      </span>
                    </div>
                  </template>
                  <el-input
                    v-model="item.equipmentType"
                    placeholder="请填写"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="车架号 (机身编号) ："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('frameNo')
                  "
                  prop="frameNo"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>车架号 (机身编号)：
                      </span>
                    </div>
                  </template>
                  <el-input
                    v-model="item.frameNo"
                    placeholder="请填写"
                  ></el-input>
                  <!-- @input="input_text($event, item, 'frameNo')" -->
                </el-form-item>
                <el-form-item
                  label="是否上牌设备"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'vehicleAccredit'
                    )
                  "
                  prop="vehicleAccredit"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span> 是否上牌设备： </span>
                    </div>
                  </template>
                  <el-switch v-model="item.vehicleAccredit"> </el-switch>
                </el-form-item>
                <el-form-item
                  label="车牌号："
                  prop="plate"
                  v-if="!pageConfig.policySubject.disShowItem.includes('plate')"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span> 车牌号： </span>
                    </div>
                  </template>
                  <el-input
                    v-model="item.plate"
                    placeholder="请填写"
                  ></el-input>
                  <!-- @input="input_text($event, item, 'frameNo')" -->
                </el-form-item>
                <el-form-item
                  label="发动机编号："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('engineNo')
                  "
                  prop="engineNo"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>发动机编号：
                      </span>
                    </div>
                  </template>
                  <el-input
                    v-model="item.engineNo"
                    placeholder="请填写"
                    @input="input_text($event, item, 'engineNo')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="生产编号："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('itemNo')
                  "
                  prop="itemNo"
                >
                  <el-input
                    v-model="item.itemNo"
                    placeholder="请填写"
                    @input="input_text($event, item, 'itemNo')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="新设备购置价："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'purchasePrice'
                    )
                  "
                  prop="purchasePrice"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>新设备购置价：
                      </span>
                    </div>
                  </template>
                  <el-input
                    v-model="item.purchasePrice"
                    placeholder="请填写"
                    @input="
                      input_num($event, item, 'purchasePrice', 99999999999999)
                    "
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="出厂日期："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'productionDate'
                    )
                  "
                  prop="productionDate"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>出厂日期：
                      </span>
                    </div>
                  </template>
                  <el-date-picker
                    v-model="item.productionDate"
                    type="date"
                    format="yyyy / MM / dd"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  label="主险保额："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'mainInsuranceAmount'
                    )
                  "
                  prop="mainInsuranceAmount"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>主险保额：
                      </span>
                    </div>
                  </template>
                  <el-input
                    v-model="item.mainInsuranceAmount"
                    placeholder="请填写"
                    @input="
                      input_num(
                        $event,
                        item,
                        'mainInsuranceAmount',
                        99999999999999
                      )
                    "
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="整备质量："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('curbWeight')
                  "
                  prop="curbWeight"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span>
                        <span style="color: #f56c6c">*</span>整备质量：
                      </span>
                    </div>
                  </template>
                  <el-input
                    v-model="item.curbWeight"
                    placeholder="请填写"
                    @input="
                      input_num(
                        $event,
                        item,
                        'curbWeight',
                        999999999999999999999999
                      )
                    "
                    maxlength="24"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="年折旧率："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'yearlyDepreciation'
                    )
                  "
                  prop="yearlyDepreciation"
                >
                  <el-input
                    v-model="item.yearlyDepreciation"
                    placeholder="请填写"
                    @input="
                      input_num($event, item, 'yearlyDepreciation', 100, 4)
                    "
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="经销商："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('agency')
                  "
                  prop="agency"
                >
                  <el-input
                    v-model="item.agency"
                    placeholder="请填写"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="产地："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('origin')
                  "
                  prop="origin"
                >
                  <template slot="label">
                    <div class="certificate-box">
                      <span> <span style="color: #f56c6c">*</span>产地： </span>
                    </div>
                  </template>
                  <el-radio-group v-model="item.origin">
                    <el-radio :label="1">国产</el-radio>
                    <el-radio :label="2">进口</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="所在位置："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('address')
                  "
                  prop="address"
                >
                  <el-input
                    v-model="item.address"
                    placeholder="请填写"
                    class="input-position"
                    maxlength="100"
                  >
                    <div slot="suffix" class="input-suffix">
                      <img
                        src="@/assets/images/policy/policyPosition.png"
                        @click="
                          isPositionDialog = true && createCorrectionType.includes('2');
                          policySubjectListIndex = index;
                        "
                        style="width: 20px; height: 20px"
                        alt
                        srcset
                      />
                    </div>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="累计工作小时："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('workTime')
                  "
                  prop="workTime"
                >
                  <el-input
                    v-model="item.workTime"
                    placeholder="请填写"
                    @input="input_num($event, item, 'workTime', 999999999999)"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div v-else>
              <el-table :data="ruleForm.policySubjectList" border>
                <el-table-column
                  label="序号"
                  type="index"
                  width="50"
                ></el-table-column>
                <el-table-column
                  label="设备品牌"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('brandName')
                  "
                  prop="brandName"
                  width="180"
                ></el-table-column>
                <el-table-column
                  label="设备类型"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'deviceTypeName'
                    )
                  "
                  prop="deviceTypeName"
                  width="180"
                ></el-table-column>
                <el-table-column
                  label="设备型号"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'equipmentType'
                    )
                  "
                  prop="equipmentType"
                  width="180"
                ></el-table-column>
                <el-table-column
                  label="车架号 (机身编号) "
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('frameNo')
                  "
                  prop="frameNo"
                ></el-table-column>
                <el-table-column
                  label="车牌号"
                  v-if="!pageConfig.policySubject.disShowItem.includes('plate')"
                  prop="plate"
                ></el-table-column>
                <el-table-column
                  label="发动机编号"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('engineNo')
                  "
                  prop="engineNo"
                ></el-table-column>
                <el-table-column
                  label="生产编号"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('itemNo')
                  "
                  prop="itemNo"
                ></el-table-column>
                <el-table-column
                  label="新设备购置价"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'purchasePrice'
                    )
                  "
                  prop="purchasePrice"
                ></el-table-column>
                <el-table-column
                  label="出厂日期"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'productionDate'
                    )
                  "
                  prop="productionDate"
                ></el-table-column>
                <el-table-column
                  label="主险保额"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'mainInsuranceAmount'
                    )
                  "
                  prop="mainInsuranceAmount"
                ></el-table-column>
                <el-table-column
                  label="整备质量"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('curbWeight')
                  "
                  prop="curbWeight"
                ></el-table-column>
                <el-table-column
                  label="年折旧率"
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes(
                      'yearlyDepreciation'
                    )
                  "
                  prop="yearlyDepreciation"
                ></el-table-column>
                <el-table-column
                  label="经销商："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('agency')
                  "
                  prop="agency"
                ></el-table-column>
                <el-table-column
                  label="产地："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('origin')
                  "
                  prop="origin"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.origin == 2
                        ? "进口"
                        : scope.row.origin == 1
                        ? "国产"
                        : "未知"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="所在位置："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('address')
                  "
                  prop="address"
                ></el-table-column>
                <el-table-column
                  label="累计工作小时："
                  v-if="
                    !pageConfig.policySubject.disShowItem.includes('workTime')
                  "
                  prop="workTime"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </el-form>
      </AssociationScrollView>
      <AssociationScrollView
        title="保障计划"
        name="6"
        v-if="pageConfig.showPlan"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm3"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
        >
          <div class="plan-table container-box">
            <div
              style="display: flex; align-items: center; margin-bottom: 18px"
            >
              <div>承保方案</div>
              <el-select
                disabled
                v-model="ruleForm.schemeName"
                v-if="permissions.policyPlanList"
                value-key="id"
                filterable
                @change="handleChangeApply"
                style="width: 350px"
                placeholder="请选择承保方案名称"
              >
                <el-option
                  v-for="dictItem in options"
                  :key="dictItem.id"
                  :label="dictItem.insureProgrammeName"
                  :value="dictItem"
                ></el-option>
              </el-select>
              <automaticCalculation
                v-model="ruleForm.autoComputeFlag"
                disabled
                style="margin-left: 78px"
              />
            </div>
            <el-table
              :data="ruleForm.policyPlanList"
              border
              style="width: 1000px"
              v-if="permissions.policyPlanList"
            >
              <el-table-column
                label="序号"
                type="index"
                width="50"
              ></el-table-column>
              <el-table-column prop="insuranceTypeName">
                <template slot="header">
                  <div>险种（条款）名称</div>
                </template>
                <template slot-scope="scope">
                  <el-form-item label>
                    <el-select
                      disabled
                      v-if="scope.row.isNewAdd"
                      v-model="scope.row.insuranceTypeName"
                      @change="
                        handleInsuranceSelect($event, scope.row, scope.$index)
                      "
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="dictItem in primaryAddAttachInsuranceTypeList"
                        :key="dictItem.id"
                        :label="dictItem.termConfigurationName"
                        :value="dictItem.id"
                      ></el-option>
                    </el-select>
                    <div v-else>
                      {{ scope.row.termConfigurationName }}
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="na2me" label="保额/限额">
                <template slot="header">
                  <div>保额/限额</div>
                </template>
                <template slot-scope="scope">
                  <el-form-item prop="sumInsured" label>
                    <el-input
                      disabled
                      v-model="scope.row.sumInsured"
                      @input="
                        input_num($event, scope.row, 'sumInsured', 999999999, 2)
                      "
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="limitCompensation" label="每次赔偿限额">
                <template slot-scope="scope">
                  <el-form-item prop="limitCompensation" label>
                    <el-input
                      disabled
                      v-model="scope.row.limitCompensation"
                      @input="
                        input_num(
                          $event,
                          scope.row,
                          'limitCompensation',
                          999999999,
                          2
                        )
                      "
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="rate" label="年费率">
                <template slot-scope="scope">
                  <el-form-item prop="rate" label>
                    <el-input
                      disabled
                      @input="
                        input_num(
                          $event,
                          scope.row,
                          'rate',
                          999999,
                          scope.row.rateType
                        )
                      "
                      v-model="scope.row.rate"
                    >
                      <el-dropdown
                        slot="suffix" disabled
                        @command="
                          handleDropdownClick($event, scope.$index, scope.row)
                        "
                      >
                        <span class="el-dropdown-link">
                          {{ scope.row.rateType ? "%" : "元" }}
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="0">元</el-dropdown-item>
                          <el-dropdown-item :command="1">%</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="premium" label="保费">
                <template slot="header">
                  <!-- <span class="nmmdx">*</span> -->
                  <div>保费(元)</div>
                </template>
                <template slot-scope="scope">
                  <div class="table-b">
                    <el-form-item prop="premium" label>
                      <el-input
                        disabled
                        v-model="scope.row.premium"
                        @input="
                          input_num($event, scope.row, 'premium', 999999999, 2)
                        "
                      ></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="ddaass" label="操作">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.stipulation"
                    type="text"
                    @click="showClause(scope.row)"
                    >查看条款</el-button
                  >
                  <el-button
                    disabled
                    plain
                    v-if="scope.$index"
                    icon="el-icon-circle-close"
                    style="color: rgba(201, 66, 66, 1)"
                    @click="
                      handleDelContent(
                        'policyPlanList',
                        scope.$index,
                        scope.row
                      )
                    "
                    >删除</el-button
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
            <div
              class="insurance-clause"
              v-if="false"
              @click="handleAddClause"
            >
              <i class="el-icon-circle-plus-outline"></i>添加条款
            </div>
            <!-- permissions.policyPlanList && createCorrectionType.includes('3') -->
            <div class="insurance-company" v-if="permissions.policyPlanList">
              <div style="display: flex; align-items: center;">
                <!-- <div style="width: 100px">总保费：</div> -->
                <div style="width: 160px">方案总保费：</div>
                <el-input
                  v-model="ruleForm.schemeAmount"
                  placeholder="请填写" disabled
                  @input="
                    input_num($event, ruleForm, 'schemeAmount', 999999999999)
                  "
                ></el-input>
                元
                <span style="display: none">{{ totalPremium }}</span>
                  <!-- :disabled="
                    ruleForm.autoComputeFlag || !createCorrectionType.includes('3')
                  " -->
              </div>
            </div>
            <div v-if="permissions.policyPlanList">
              <div style="margin-bottom: 10px;">
                是否直接批改保费：
                <el-radio-group
                  v-model="ruleForm.directCorrect" :disabled="!createCorrectionType.includes('3')"
                >
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </div>
              <div style="display: flex; align-items: center;margin-bottom: 10px;position: relative;">
                <div v-if="ruleForm.directCorrect">
                  批改保费类型：
                  <el-radio-group
                    v-model="ruleForm.premiumType" :disabled="!createCorrectionType.includes('3')"
                  >
                    <el-radio :label="true">批增</el-radio>
                    <el-radio :label="false">批减</el-radio>
                  </el-radio-group>
                </div>
                <div style="display: flex; align-items: center;" v-if="ruleForm.directCorrect">
                  <div style="width:120px;margin-left: 20%;">批改保费：</div>
                  <el-input type="number"
                    v-model="ruleForm.correctAmount" :disabled="!createCorrectionType.includes('3')"
                    @input="
                      input_num($event, ruleForm, 'correctAmount', 999999999999, 2)
                    "
                  ></el-input>
                </div>
                <div class="insurance-company" style="margin-top:0;position: absolute;right:0"><span style="margin-right:30px">批改前总保费{{ ruleForm.oldTotalPremium }}</span> 批改后总保费：{{ ruleForm.totalPremium }}</div>
              </div>
              <div v-if="ruleForm.directCorrect">
                <div>备注：</div>
                <el-input
                  :disabled="!createCorrectionType.includes('3')"
                  type="textarea"
                  v-model="ruleForm.directRemark"
                  show-word-limit
                  :rows="6"
                  maxlength="3000"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
            <div class="clause-title" v-if="permissions.extensionClause">
              免费扩展条款
            </div>
            <el-input
              :disabled="!createCorrectionType.includes('4')"
              type="textarea"
              v-if="permissions.extensionClause"
              v-model="ruleForm.extensionClause"
              show-word-limit
              :rows="6"
              maxlength="3000"
              placeholder="请输入内容"
            ></el-input>
            <div class="clause-title" v-if="permissions.specialAgreement">
              特别约定
            </div>
            <el-input
              :disabled="!createCorrectionType.includes('4')"
              type="textarea"
              v-if="permissions.specialAgreement"
              v-model="ruleForm.specialAgreement"
              show-word-limit
              :rows="6"
              maxlength="3000"
              placeholder="请输入内容"
            ></el-input>
            <div class="clause-title" v-if="permissions.specialVersion">
              特别说明
            </div>
            <el-input
              :disabled="!createCorrectionType.includes('4')"
              type="textarea"
              v-if="permissions.specialVersion"
              v-model="ruleForm.specialVersion"
              show-word-limit
              :rows="6"
              maxlength="3000"
              placeholder="请输入内容"
            ></el-input>
            <div class="clause-title" ref="absoluteDeductible">
              每次事故绝对免赔额
              <!-- <span
                style="font-size:14px;font-weight:normal"
              >(至少勾选和填写一项)</span>-->
            </div>
            <div v-if="permissions.absoluteDeductible">
              <div class="policy-label-style">
                <el-checkbox
                  :disabled="!createCorrectionType.includes('4')"
                  v-model="ruleForm.absoluteDeductibleCheckbox"
                  @change="handleCheckboxChange($event, 1)"
                  >扩展不计免赔1（0免赔）</el-checkbox
                >
              </div>
              <el-input
                :disabled="
                  !createCorrectionType.includes('4') ||
                  !ruleForm.absoluteDeductibleCheckbox
                "
                type="textarea"
                style="margin-bottom: 12px"
                v-model="ruleForm.absoluteDeductible"
                show-word-limit
                maxlength="1000"
                :rows="6"
                placeholder="请输入内容"
              ></el-input>
            </div>
            <div v-if="permissions.absoluteDeductible2">
              <div class="policy-label-style">
                <el-checkbox
                  :disabled="!createCorrectionType.includes('4')"
                  v-model="ruleForm.absoluteDeductible2Checkbox"
                  @change="handleCheckboxChange($event, 2)"
                  >扩展不计免赔2（绝对免赔1000元）</el-checkbox
                >
              </div>
              <el-input
                :disabled="
                  !createCorrectionType.includes('4') ||
                  !ruleForm.absoluteDeductible2Checkbox
                "
                type="textarea"
                style="margin-bottom: 12px"
                v-model="ruleForm.absoluteDeductible2"
                show-word-limit
                maxlength="1000"
                :rows="6"
                placeholder="请输入内容"
              ></el-input>
            </div>
            <div v-if="permissions.absoluteDeductible3">
              <div class="policy-label-style">
                <el-checkbox
                  :disabled="!createCorrectionType.includes('4')"
                  v-model="ruleForm.absoluteDeductible3Checkbox"
                  @change="handleCheckboxChange($event, 3)"
                  >未扩展不计免赔</el-checkbox
                >
              </div>
              <el-input
                :disabled="
                  !createCorrectionType.includes('4') ||
                  !ruleForm.absoluteDeductible3Checkbox
                "
                type="textarea"
                v-model="ruleForm.absoluteDeductible3"
                show-word-limit
                maxlength="2000"
                :rows="6"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
        </el-form>
        <!-- <div v-if="permissions.ep">
          <div class="policy-label-style">电子保单</div>
          <div
            v-if="ruleForm.ep && ruleForm.ep.fileType"
            class="display: flex;align-items: center;"
          >
            <img
              src="@/assets/images/policy/png.png"
              class="policy-ep-img"
              alt
              v-if="ruleForm.ep.fileType == 'png'"
              srcset
            />
            <img
              src="@/assets/images/policy/jpg.png"
              class="policy-ep-img"
              v-else-if="ruleForm.ep.fileType == 'jpg'"
              alt
              srcset
            />
            <img
              src="@/assets/images/policy/jpeg.png"
              class="policy-ep-img"
              v-else-if="ruleForm.ep.fileType == 'jpeg'"
              alt
              srcset
            />
            <img
              src="@/assets/images/policy/pdf.png"
              class="policy-ep-img"
              v-else-if="ruleForm.ep.fileType == 'pdf'"
              alt
              srcset
            />
            <span style="color: #333333">{{ ruleForm.ep.fileName }}</span>
            <span class="ep-b" @click="onPreview">预览</span>
            <span class="ep-b" @click="onDownload">下载</span>
          </div>
          <div v-else>无</div>
        </div> -->
      </AssociationScrollView>

      <AssociationScrollView
        title="保单文件"
        name="6"
        v-if="pageConfig.showNewPlan"
      >
        <div v-if="permissions.ep">
          <div class="policy-label-style">电子保单</div>
          <div
            v-if="ruleForm.ep && ruleForm.ep.fileType"
            class="display: flex;align-items: center;"
          >
            <img
              src="@/assets/images/policy/png.png"
              class="policy-ep-img"
              alt
              v-if="ruleForm.ep.fileType == 'png'"
              srcset
            />
            <img
              src="@/assets/images/policy/jpg.png"
              class="policy-ep-img"
              v-else-if="ruleForm.ep.fileType == 'jpg'"
              alt
              srcset
            />
            <img
              src="@/assets/images/policy/jpeg.png"
              class="policy-ep-img"
              v-else-if="ruleForm.ep.fileType == 'jpeg'"
              alt
              srcset
            />
            <img
              src="@/assets/images/policy/pdf.png"
              class="policy-ep-img"
              v-else-if="ruleForm.ep.fileType == 'pdf'"
              alt
              srcset
            />
            <span style="color: #333333">{{ ruleForm.ep.fileName }}</span>
            <span class="ep-b" @click="onPreview">预览</span>
            <span class="ep-b" @click="onDownload">下载</span>
          </div>
          <div v-else>无</div>
        </div>
        <FormListUpload
          required
          :isEdit="createCorrectionType.includes('2')"
          :limit="5"
          :limitSize="10"
          :limitType="['pdf', 'jpg', 'png', 'jpeg']"
          tips="（只支持pdf,jpg,png,jpeg格式，单个文件不超过10MB，最多上传5个）"
          title="营业执照/身份证"
          attachmentType="yyzz"
          v-model="ruleForm.attachments.yyzz"
        ></FormListUpload>
        <FormListUpload
          required
          :isEdit="createCorrectionType.includes('2')"
          title="开票信息"
          attachmentType="kpxx"
          v-model="ruleForm.attachments.kpxx"
        ></FormListUpload>
        <FormListUpload
          required
          :isEdit="createCorrectionType.includes('2')"
          title="其他信息"
          attachmentType="qtxx"
          v-model="ruleForm.attachments.qtxx"
        ></FormListUpload>
      </AssociationScrollView>

      <AssociationScrollView
        title="保单文件"
        name="7"
        v-if="pageConfig.showPolicyUpload"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm3"
          class="el-collapse-box"
        >
          <div class="p-file">
            <!-- <el-form-item prop="attachments.dzbd" ref="attachments.dzbd">
              <FormListUpload
                required
                :isEdit="createCorrectionType == 3"
                title="电子保单"
                attachmentType="dzbd"
                v-model="ruleForm.attachments.dzbd"
              ></FormListUpload>
            </el-form-item> -->

            <div v-if="permissions.ep">
              <div class="policy-label-style">电子保单</div>
              <div
                v-if="ruleForm.ep && ruleForm.ep.fileType"
                class="display: flex;align-items: center;"
              >
                <img
                  src="@/assets/images/policy/png.png"
                  class="policy-ep-img"
                  alt
                  v-if="ruleForm.ep.fileType == 'png'"
                  srcset
                />
                <img
                  src="@/assets/images/policy/jpg.png"
                  class="policy-ep-img"
                  v-else-if="ruleForm.ep.fileType == 'jpg'"
                  alt
                  srcset
                />
                <img
                  src="@/assets/images/policy/jpeg.png"
                  class="policy-ep-img"
                  v-else-if="ruleForm.ep.fileType == 'jpeg'"
                  alt
                  srcset
                />
                <img
                  src="@/assets/images/policy/pdf.png"
                  class="policy-ep-img"
                  v-else-if="ruleForm.ep.fileType == 'pdf'"
                  alt
                  srcset
                />
                <span style="color: #333333">{{ ruleForm.ep.fileName }}</span>
                <span class="ep-b" @click="onPreview">预览</span>
                <span class="ep-b" @click="onDownload">下载</span>
              </div>
              <div v-else>无</div>
            </div>
            <el-form-item prop="attachments.yyzz" ref="attachments.yyzz">
              <FormListUpload
                required
                :isEdit="createCorrectionType.includes('3')"
                :limit="5"
                :limitSize="10"
                :limitType="['pdf', 'jpg', 'png', 'jpeg']"
                tips="（只支持pdf,jpg,png,jpeg格式，单个文件不超过10MB，最多上传5个）"
                title="营业执照"
                attachmentType="yyzz"
                v-model="ruleForm.attachments.yyzz"
              ></FormListUpload>
            </el-form-item>
            <el-form-item prop="attachments.kpxx" ref="attachments.kpxx">
              <FormListUpload
                :isEdit="createCorrectionType.includes('3')"
                title="开票信息"
                :limit="1"
                :limitSize="10"
                :limitType="[
                  'doc',
                  'docx',
                  'pdf',
                  'xlsx',
                  'jpg',
                  'jpeg',
                  'png',
                  'pptx',
                ]"
                tips="（只支持doc,docx,pdf,xlsx,jpg,jpeg,png,pptx格式，单个文件不超过10MB，最多上传1个文件）"
                attachmentType="kpxx"
                v-model="ruleForm.attachments.kpxx"
              ></FormListUpload>
            </el-form-item>
            <el-form-item prop="attachments.qtxx" ref="attachments.qtxx">
              <FormListUpload
                tips="支持格式：.doc .docx .jpg .jpeg .png .pdf  ，单个文件不能超过10MB，最多上传20个文件"
                :limit="20"
                :limitSize="10"
                :isEdit="createCorrectionType.includes('3')"
                title="其他信息"
                attachmentType="qtxx"
                v-model="ruleForm.attachments.qtxx"
              ></FormListUpload>
            </el-form-item>
          </div>
        </el-form>
      </AssociationScrollView>
      <!-- 其他信息 -->
      <AssociationScrollView title="批单信息" name="8">
        <el-form
          :model="ruleForm"
          :rules="rules"
          inline
          ref="ruleForm4"
          label-position="top"
          label-width="100px"
          class="el-collapse-box"
        >
          <el-form-item
            prop="approval"
            required
            ref="approval"
            v-if="permissions.approval"
          >
            <template slot="label">
              <span>批文：</span>
            </template>
            <el-input
              style="width: 980px"
              type="textarea"
              v-model="ruleForm.approval"
              :rows="6"
              placeholder="请输入批文"
            ></el-input>
          </el-form-item>
          <div v-if="createCorrectionType.includes('4')">
            <div>
              <el-form-item prop="orderType" v-if="permissions.orderType">
                <template slot="label">
                  <span>其他：</span>
                </template>
                <el-radio-group
                  v-model="ruleForm.orderType"
                  @change="orderTypeChange"
                >
                  <el-radio :label="5">退保</el-radio>
                  <el-radio :label="6">注销</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="ruleForm.orderType == 5 || ruleForm.orderType == 6">
              <el-form-item
                label="退保保费"
                required
                prop="cancelPremium"
                ref="cancelPremium"
                v-if="permissions.accountName"
              >
                <el-input
                  v-model="ruleForm.cancelPremium"
                  @input="
                    input_num($event, ruleForm, 'cancelPremium', 999999999999)
                  "
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="ruleForm.orderType == 5">
              <div>退保收款信息</div>
              <el-form-item
                label="户名"
                required
                prop="accountName"
                ref="accountName"
                v-if="permissions.accountName"
              >
                <el-input v-model="ruleForm.accountName"></el-input>
              </el-form-item>
              <el-form-item
                label="开户行"
                prop="accountBlank"
                ref="accountBlank"
                v-if="permissions.accountBlank"
              >
                <el-input v-model="ruleForm.accountBlank"></el-input>
              </el-form-item>
              <el-form-item
                label="银行账号"
                prop="accountNumber"
                v-if="permissions.accountNumber"
              >
                <el-input v-model="ruleForm.accountNumber"></el-input>
              </el-form-item>
              <el-form-item
                label="设领款人证件类型"
                prop="idType"
                v-if="permissions.idType"
              >
                <el-select v-model="ruleForm.idType" placeholder="请选择">
                  <el-option :key="1" label="身份证" :value="1" />
                  <el-option :key="2" label="企业营业执照" :value="2" />
                  <el-option :key="3" label="其他" :value="3" />
                  <el-option :key="-1" label="无" :value="-1" />
                </el-select>
              </el-form-item>
              <el-form-item
                label="证件号码"
                prop="idNumber"
                v-if="permissions.idNumber"
              >
                <el-input v-model="ruleForm.idNumber"></el-input>
              </el-form-item>
              <el-form-item
                label="联系电话"
                prop="phone"
                v-if="permissions.phone"
              >
                <el-input v-model="ruleForm.phone"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </AssociationScrollView>
    </AssociationScroll>

    <!-- 地图选择地址 -->
    <positionDialog v-model="isPositionDialog" @submit="handleAddressVal" />
    <!-- 关联上一年保单 -->
    <AssociationPolicy
      v-model="isAssociationDialog"
      @submit="handleChangeDetails"
      :excludePolicyId="excludePolicyId"
    />
    <!-- 标的信息导入 -->
    <subjectImport
      v-model="isSubjectDialog"
      @changeImport="handleChangeSubjectList"
    />
    <!-- 示例 -->
    <ExampleDialog v-model="isExampleDialog" :typeName="ExampleTypeName" />
    <!-- 示例 -->
    <IdentityCardUpDate
      v-model="isIdentityCard"
      isUpdateOss
      @identify="handleIdentifySuccess"
    />
    <clauseDialog v-model="innerVisible" :info="clauseInfo" />
    <div class>
      <el-image
        style="width: 0px; height: 0px"
        ref="img-box"
        :preview-src-list="srcList"
      ></el-image>
    </div>
    <repetitionHistory
      v-model="repetitionHistoryVisible"
      :formData="repetitionHistoryForm"
    />
    <subjectDocumentRecognize
      :show.sync="subjectRecognizeShow"
      @submit="handleIdentifySubjectCard"
    ></subjectDocumentRecognize>
  </div>
</template>

<script>
import { diffObject } from "../../utils/index";
import IdentityCardUpDate from "@/views/policyManagement/components/IdentityCardUpDate.vue";
import ExampleDialog from "@/views/policyManagement/components/ExampleDialog.vue";
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import positionDialog from "@/views/policyManagement/components/positionDialog.vue";
import AssociationPolicy from "@/views/policyManagement/components/AssociationPolicy.vue";
import subjectImport from "@/views/policyManagement/components/subjectImport.vue";
import { getUserByCharge } from "@/api/SystemData";
import clauseDialog from "@/views/policyManagement/components/ClauseDialog.vue";
import repetitionHistory from "@/views/policyManagement/components/repetitionHistory";
import automaticCalculation from "@/views/EnquiryManagement/components/automaticCalculation.vue";
import { isCardNo, phoneFun, uploadFile, fileToBase64 } from "@/utils/index";
import {
  dictionaryBatch,
  listScheme,
  downloadSubject,
  deletPolicyPlan,
  deletInsPolicyArchivePlayers,
  businessLicense,
  selectPlayers,
  selectPlayerById,
  insEnquiryArchiveRepeat,
  getInsureProgrammeByCategory,
  getInsureClauseByCategory,
  getUpstreamAgentList,
  certificateObject,
} from "@/api/policy";
import VDistpicker from "v-distpicker";
import { getPageConfig } from "@/views/CorrectionSheetManagement/pagesConfig.js";
import FormListUpload from "@/components/FormListUpload/index.vue";
import dayjs from "dayjs";
import { OSSDirectPass } from "@/api/oss.js";
import subjectDocumentRecognize from "@/views/policyManagement/components/subjectDocumentRecognize.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    orderType: {
      type: [String, Number],
      default: "3", //  类型，1：询价单、2：保单、3：批单
    },
    createCorrectionType: {
      type: [String, Number, Array],
      default: ()=> [],
    },
    permissions: {
      type: Object,
      default: () => {},
    },
    uploadData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailData: {
      handler(v, o) {
        if (v && v.id) {
          this.excludePolicyId = v.id + "";
          let ruleForm = JSON.parse(JSON.stringify(v));
          ruleForm.autoComputeFlag === undefined
            ? (ruleForm.autoComputeFlag = true)
            : "";
          ruleForm.startDate
            ? (ruleForm.startDate = dayjs(ruleForm.startDate).format(
                "YYYY-MM-DD"
              ))
            : "";
          ruleForm.endDate
            ? (ruleForm.endDate = dayjs(ruleForm.endDate).format("YYYY-MM-DD"))
            : "";
          //  if (ruleForm.policyPlanList && ruleForm.policyPlanList.length) {
          //    let ind = ruleForm.policyPlanList.findIndex(
          //      (val) => val.insuranceTypeCode == "primaryInsuranceType"
          //    );
          //    if (ind > 0) {
          //      let policyPlanList = JSON.parse(
          //        JSON.stringify(ruleForm.policyPlanList)
          //      );
          //      policyPlanList.splice(ind, 1);
          //      policyPlanList.unshift(ruleForm.policyPlanList[ind]);
          //      ruleForm.policyPlanList = policyPlanList;
          //    }
          //  }
          this.handleReverseDealWith(ruleForm.policyInsuredList);
          this.handleReverseDealWith(ruleForm.policyHolderList);
          this.handleReverseDealWith(ruleForm.beneficiaryList);
          this.ruleForm = ruleForm;
          this.init();
        }
      },
      deep: true,
      immediate: true,
    },
    "ruleForm.policySubjectList": {
      deep: true,
      immediate: true,
      handler(v, o) {
        //   let oData = JSON.stringify([
        //     {
        //       address: "",
        //       addressLonlat: "",
        //       agency: "",
        //       brand: "",
        //       brandName: "",
        //       curbWeight: "",
        //       deviceType: "",
        //       deviceTypeName: "",
        //       engineNo: "",
        //       equipmentType: "",
        //       frameNo: "",
        //       itemNo: "",
        //       mainInsuranceAmount: "",
        //       origin: 1,
        //       premium: "",
        //       productionDate: "",
        //       purchasePrice: "",
        //       workTime: "",
        //       yearlyDepreciation: "",
        //     },
        //   ]);
        //   if (oData != JSON.stringify(o)) {
        this.setPrimaryInsuranceTypeSumInsured(v);
        //   }
      },
    },
    "ruleForm.policyPlanList": {
      immediate: true,
      deep: true,
      handler(n) {
        //计算
        //   let spimObj = null;
        //   n.forEach((item, i) => {
        //     spimObj = this.specialProtectionScheme.find(spim => {
        //       if (spim.name == this.ruleForm.schemeName && spim.itemName == item.insuranceTypeName) {
        //         spim.index = i;
        //         let oObj = this.policyPlanListOld.find(oitem => {
        //           return spim.itemName == oitem.insuranceTypeName;
        //         });
        //         if (oObj) {
        //           spim.type.forEach((ty, ti) => {
        //             if (Number(item[ty] || 0) == Number(oObj[ty] || 0)) {
        //               spim[`fun${ ti + 1 }`](item, n, i);
        //             }
        //           });
        //         }
        //         return true;
        //       }
        //     });
        //   });
        //   this.policyPlanListOld = JSON.parse(JSON.stringify(n));
      },
    },
    "ruleForm.acceptCompanyList": {
      immediate: true,
      deep: true,
      handler(n) {
        n.map((val, index) => {
          this.handleChangeCompany(val.company, index);
        });
      },
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!phoneFun(value) && value) {
        callback(new Error("请输正确的号码"));
      } else {
        // this.$refs.ruleForm.validateField('checkPass');
        callback();
      }
    };
    var validatePFile = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      subjectRecognizeShow: false,
      subjectRecognizeObj: null,
      ///承保方案20230802
      //条款
      primaryAddAttachInsuranceTypeList: [],
      //业务代理人
      upstreamAgentOptions: [],
      //辅助对象
      assistObj: {
        schemeNameObj: {},
      },
      //
      isExampleDialog: false,
      isIdentityCard: false,
      ExampleTypeName: "identity",
      oldruleForm: {},
      pageConfig: {},
      ruleForm: {
        acceptCompanyList: [
          {
            acceptPrimary: true,
            acceptRate: 0,
            branch: "",
            company: 0,
            companyName: "",
            id: 0,
            orderId: 0,
            sort: 0,
          },
        ],
        agentId: 0,
        agentName: "",
        autoComputeFlag: true,
        sourceChannel: "",
        agentNo: "",
        applicantMode: 0,
        applicantModeName: "",
        applicantStore: 0,
        applicantStoreName: "",
        applicantType: 0,
        applicantTypeName: "",
        attachmentType: "",
        attachments: {
          yyzz: "",
          kpxx: "",
          qtxx: "",
        },
        beneficiaryList: [
          {
            cardEnd: "",
            cardNo: "",
            cardStart: "",
            cardType: 1,
            city: "",
            contactEmail: "",
            contactName: "",
            contactPhone: "",
            county: "",
            detailAddress: "",
            playersName: "",
            playersType: 1,
            province: "",
          },
        ],
        city: "",
        endDate: "",
        ep: {},
        extensionClause: "",
        id: 0,
        enquiryNo: "",
        lastPolicyNo: "",
        policyHolderList: [
          {
            cardEnd: "",
            cardNo: "",
            cardStart: "",
            cardType: 1,
            city: "",
            contactEmail: "",
            contactName: "",
            contactPhone: "",
            county: "",
            detailAddress: "",
            playersName: "",
            playersType: 1,
            province: "",
          },
        ],
        policyInsuredList: [
          {
            cardEnd: "",
            cardNo: "",
            cardStart: "",
            cardType: 1,
            city: "",
            contactEmail: "",
            contactName: "",
            contactPhone: "",
            county: "",
            detailAddress: "",
            playersName: "",
            playersType: 1,
            province: "",
          },
        ],
        policyNo: "",
        policyPlanList: [
          {
            insuranceType: "",
            insuranceTypeCode: "primaryInsuranceType",
            insuranceTypeName: "",
            limitCompensation: "",
            premium: "",
            rate: "",
            rateType: 1,
            sumInsured: "",
          },
        ],
        policySubjectList: [
          {
            address: "",
            addressLonlat: "",
            agency: "",
            brand: "",
            brandName: "",
            curbWeight: "",
            deviceType: "",
            deviceTypeName: "",
            engineNo: "",
            equipmentType: "",
            frameNo: "",
            itemNo: "",
            mainInsuranceAmount: "",
            origin: 1,
            premium: "",
            productionDate: "",
            purchasePrice: "",
            workTime: "",
            yearlyDepreciation: "",
            vehicleAccredit: false,
          },
        ],
        projectId: "",
        projectName: "",
        projectRemark: "",
        province: "",
        salesmanId: "",
        salesmanName: "",
        specialAgreement: "",
        startDate: "",
        totalPremium: 0,
        accountName: "",
        cancelPremium: "",
        accountNumber: "",
        accountBlank: "",
        idType: "",
        idNumber: "",
        phone: "",
        absoluteDeductibleCheckbox: "",
        absoluteDeductible2Checkbox: "",
        absoluteDeductible3Checkbox: "",
        approval: "",
        isSingle: true,
        recorderId: "",
        schemeName: "",
        insuranceOfDate: "",
        specialVersion: "",
        policySubjectNum: 0,
        upstreamAgentId: "",
        upstreamAgentName: "",
        directCorrect: false,
        premiumType: true,
        correctAmount: '',
        directRemark: '',
      },
      rules: {
        applicantType: [
          {
            required: this.$route.meta.policyType == 1,
            message: "请选择投保方式",
            trigger: "change",
          },
        ],
        policyNo: [
          { required: true, message: "请输入保单号", trigger: "change" },
        ],
        policyName: [
          { required: true, message: "请输入险种名称", trigger: "change" },
        ],
        projectId: [
          { required: true, message: "请选择项目名称", trigger: "change" },
        ],
        startDate: [
          { required: true, message: "请选择保险期限", trigger: "change" },
        ],
        salesmanId: [
          { required: true, message: "请选择业务员", trigger: "change" },
        ],
        recorderId: [
          { required: true, message: "请选择录单员", trigger: "change" },
        ],
        sourceChannel: [
          { required: true, message: "请输入渠道来源", trigger: "change" },
        ],
        totalPremium: [
          { required: true, message: "请输入总保费", trigger: "change" },
        ],
        agentNo: [
          {
            required: true,
            message: "请输入代理人职业编码",
            trigger: "change",
          },
        ],
        insuredMobile: [
          {
            validator: validateMobile,
            message: "请输入正确的号码",
            trigger: "blur",
          },
        ],
        applicantMobile: [
          {
            validator: validateMobile,
            message: "请输入正确的号码",
            trigger: "blur",
          },
        ],
        accountName: [
          { required: true, message: "请输入户名", trigger: "blur" },
          { required: true, message: "请输入户名", trigger: "change" },
        ],
        cancelPremium: [
          { required: true, message: "请输入退保保费", trigger: "blur" },
          { required: true, message: "请输入退保保费", trigger: "change" },
        ],
        approval: [
          {
            required: true,
            message: "请输入批文",
            trigger: ["change", "blur"],
          },
        ],
        "attachments.dzbd": [
          {
            validator: validatePFile,
            message: "请上传电子保单！",
            trigger: ["change"],
          },
        ],
        "attachments.yyzz": [
          {
            validator: validatePFile,
            message: "请上传营业执照！",
            trigger: ["change"],
          },
        ],
        //   'attachments.kpxx': [{ validator: validatePFile, message: '请上传开票信息！', trigger: ['change'] }],
        //   'attachments.qtxx': [{ validator: validatePFile, message: '请上传其他信息！', trigger: ['change'] }]
      },
      options: [],
      identCardType: [],
      insuranceCompany: [],
      insuranceBranch: [],
      vehicleBrand: [],
      vehicleType: [],
      attachInsuranceType: [],
      attachData: [],
      primaryInsuranceType: [],
      applicantStore: [],
      applicantMode: [],
      applicantType: [],
      userOptions: [],
      radio: 1,
      startType: 2,
      isPositionDialog: false,
      isAssociationDialog: false,
      isSubjectDialog: false,
      srcList: [],
      excludePolicyId: "",
      temporaryVal: "",
      innerVisible: false,
      clauseInfo: {},
      projectNameOptions: [],
      provinceList: [],
      FCXInsCategoryList: [],
      CXInsCategoryList: [],
      JobCategoryList: [],
      oldSubjectList: null,
      policySubjectListIndex: 0,
      queryDataType: null,
      specialProtectionScheme: [
        {
          name: "起重机方案",
          type: ["premium", "sumInsured"],
          // 不进行横向计算
          calculation: true,
          itemName: "扩展不计免赔",
          index: -1,
          fun1(item, arry, i) {
            let sum = 0;
            arry.forEach((item, index) => {
              if (i != index) {
                sum += Number(item.premium || 0);
              }
            });
            item.premium = (sum * (Number(item.rate || 0) / 100)).toFixed(2);
          },
          fun2(item, arry, i) {
            item.sumInsured = arry[0].sumInsured;
          },
        },
        {
          name: "平安汽车吊承保方案",
          itemName: "扩展不计免赔",
          type: ["sumInsured"],
          calculation: true,
          index: -1,
          fun1(item, arry, i) {
            let sum = 0;
            arry.forEach((item, index) => {
              if (i != index) {
                sum += Number(item.premium || 0);
              }
            });
            item.sumInsured = sum.toFixed(2);
            //item.premium = (sum * (Number(item.rate || 0) / 100)).toFixed(2);
          },
        },
      ],
      policyPlanListOld: {},
      repetitionHistoryForm: {
        cardNo: "",
        salesmanId: "",
        dataType: "",
        orderType: "",
      },
      repetitionHistoryVisible: false,
      AssignmentObj: null,
      AssignmentObjType: null,
    };
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    VDistpicker,
    positionDialog,
    EmployeeSelect,
    AssociationPolicy,
    subjectImport,
    IdentityCardUpDate,
    ExampleDialog,
    clauseDialog,
    FormListUpload,
    repetitionHistory,
    automaticCalculation,
    subjectDocumentRecognize,
  },
  created() {
    this.oldruleForm = Object.assign({}, this.ruleForm);
    this.init();
  },
  computed: {
    findPolicyInsuredList() {
      return this.ruleForm.policyHolderList.filter(
        (val) =>
          val.playersName &&
          (val.cardType || val.cardType === "0" || val.cardType === 0)
      );
    },
    findInsuredList() {
      return this.ruleForm.policyInsuredList.filter(
        (val) =>
          val.playersName &&
          (val.cardType || val.cardType === "0" || val.cardType === 0)
      );
    },
    totalPremium() {
      // this.$route.meta.policyType != 1 ||  绩效管理移除
      if (!this.ruleForm.autoComputeFlag) {
        this.ruleForm.totalPremium = Number(this.ruleForm.oldTotalPremium) + (typeof this.ruleForm.premiumType === 'boolean' ? this.ruleForm.premiumType ? this.ruleForm.correctAmount : -this.ruleForm.correctAmount : 0);
      };
      let num = 0;
      this.ruleForm.policyPlanList.map((val) => {
        num += Number(val.premium);
      });
      if (this.ruleForm.startDate && this.ruleForm.endDate) {
        let timeInterval = dayjs(
          dayjs(this.ruleForm.endDate).format("YYYY-MM-DD")
        ).diff(
          dayjs(this.ruleForm.startDate).format("YYYY-MM-DD"),
          "month",
          true
        );
        timeInterval = Math.ceil(timeInterval);
        num *= this.calculationShortTermRates(timeInterval);
      }
      // this.ruleForm.schemeAmount = num.toFixed(2);
      // typeof this.ruleForm.directCorrect === 'boolean'
      this.ruleForm.totalPremium = Number(this.ruleForm.oldTotalPremium) + (this.ruleForm.directCorrect ? (this.ruleForm.premiumType ? this.ruleForm.correctAmount || 0 : -(this.ruleForm.correctAmount || 0)) : 0);
      return num;
    },
  },
  methods: {
    handleInput(item, key) {
      if (key === "sumInsured") {
        item.limitCompensation = item.sumInsured;
      }
      if (
        (key === "sumInsured" || key === "rate") &&
        item.sumInsured &&
        item.rate &&
        item.rateType === 1
      ) {
        item.premium = (item.sumInsured * item.rate * 0.01).toFixed(2);
      }
    },
    orderTypeChange(val) {
      if (val == 6) {
        this.ruleForm.idNumber = "";
        this.ruleForm.phone = "";
        this.ruleForm.idType = "";
        this.ruleForm.accountNumber = "";
        this.ruleForm.accountBlank = "";
        this.ruleForm.accountName = "";
      }
      this.ruleForm.autoComputeFlag = false;
      this.ruleForm.totalPremium = this.detailData.totalPremium;
    },
    init() {
      this.pageConfig = getPageConfig(this.$route.meta.policyType);
      // 证件类型identCardType 保险公司（insuranceCompany）、设备品牌（vehicleBrand）、设备类型（vehicleType）主险种编码：primaryInsuranceType 附加险种编码：attachInsuranceType 投保门店applicantStore 投保方式applicantMode 投保类型applicantType
      dictionaryBatch({
        codes: `identCardType,insuranceCompany,vehicleBrand,vehicleType,CXvehicleBrand,CXvehicleType,primaryInsuranceType,attachInsuranceType,applicantStore,applicantMode,FCXApplicantType,applicantType,${this.pageConfig.dictProjectName},province,FCXInsCategory,JobCategory,CXInsCategory`,
      }).then((res) => {
        if (res && res.data) {
          const {
            insuranceCompany,
            vehicleBrand,
            vehicleType,
            CXvehicleBrand,
            CXvehicleType,
            identCardType,
            attachInsuranceType,
            primaryInsuranceType,
            applicantStore,
            applicantMode,
            applicantType,
            FCXApplicantType,
            //projectName,
            province,
            FCXInsCategory,
            CXInsCategory,
            JobCategory,
          } = res.data;
          const projectName = res.data[this.pageConfig.dictProjectName];
          this.insuranceCompany = insuranceCompany || [];
          //  this.vehicleBrand = vehicleBrand || [];
          //  this.vehicleType = vehicleType || [];
          this.vehicleBrand =
            (this.$route.meta.policyType == 1
              ? vehicleBrand
              : this.$route.meta.policyType == 2
              ? CXvehicleBrand
              : vehicleBrand) || [];
          this.vehicleType =
            (this.$route.meta.policyType == 1
              ? vehicleType
              : this.$route.meta.policyType == 2
              ? CXvehicleType
              : vehicleType) || [];
          this.identCardType = identCardType || [];
          this.primaryInsuranceType = primaryInsuranceType || [];
          this.attachInsuranceType = attachInsuranceType || [];
          this.attachData = attachInsuranceType || [];
          this.applicantStore = applicantStore || [];
          this.applicantMode = applicantMode || [];
          //  this.applicantType = applicantType || [];
          this.applicantType =
            (this.$route.meta.policyType == 1
              ? applicantType
              : this.$route.meta.policyType == 2
              ? applicantType
              : FCXApplicantType) || [];
          this.projectNameOptions = projectName || [];
          this.provinceList = province || [];
          this.FCXInsCategoryList = FCXInsCategory || [];
          this.CXInsCategoryList = CXInsCategory || [];
          this.JobCategoryList = JobCategory || [];
          //  if (this.ruleForm.policyPlanList.length > 0) {
          //    switch (this.ruleForm.policyPlanList[0].insuranceTypeName) {
          //      case "财产一切险主险":
          //        this.attachInsuranceType = this.attachData.filter((val) => {
          //          return val.dictKey > 100 && val.dictKey <= 200;
          //        });
          //        break;
          //      case "财产综合险主险":
          //        this.attachInsuranceType = this.attachData.filter((val) => {
          //          return val.dictKey > 200 && val.dictKey <= 300;
          //        });
          //        break;
          //      case "第三者责任险":
          //        this.attachInsuranceType = this.attachData.filter((val) => {
          //          return val.dictKey > 300 && val.dictKey <= 400;
          //        });
          //        break;
          //      case "起重机综合险":
          //        this.attachInsuranceType = this.attachData.filter((val) => {
          //          return val.dictKey > 400 && val.dictKey <= 500;
          //        });
          //        break;
          //      case "工程机械设备险":
          //        this.attachInsuranceType = this.attachData.filter((val) => {
          //          return val.dictKey > 200 && val.dictKey <= 300;
          //        });
          //        break;
          //      case "驾意险":
          //        this.attachInsuranceType = [];
          //        break;
          //    }
          //    this.ruleForm.policyPlanList.forEach((element) => {
          //      element.stipulation = this.handleAttachSelect(
          //        Number(element.insuranceType),
          //        element
          //      );
          //    });
          //  }
        }
      });
      // listScheme({ category: this.$route.meta.policyType }).then((res) => {
      //   //   console.log(res);
      //   this.options = res.data;
      // });
      // //条款列表(只拿附加险)
      getInsureClauseByCategory({ category: this.$route.meta.policyType }).then(
        (res) => {
          this.primaryAddAttachInsuranceTypeList = res.data;
        }
      );
      //新的的承保方案列表
      this.getInsuranceCompanyClauseByCategoryAndCom(this.getMainInsCom());
      getUserByCharge().then((res) => {
        this.userOptions = res.data.map((val) => {
          val.userId += "";
          return val;
        });
        if (!this.ruleForm.id) {
          this.ruleForm.recorderId = this.$store.state.userInfo.MJUserId;
          this.ruleForm.recorderName = this.$store.state.userInfo.name;
        }
      });
      this.policyPlanListOld = JSON.parse(
        JSON.stringify(this.ruleForm.policyPlanList)
      );
    },
    showClause(value) {
      this.clauseInfo = value;
      this.innerVisible = true;
    },
    handleAttachSelect(value, item) {
      let temp = this.attachInsuranceType.find((el) => {
        return el.dictKey === value;
      });
      if (temp) {
        item.stipulation = temp.stipulation;
        item.dictValue = temp.dictValue;
        item.insuranceTypeName = temp.dictValue;
        //return temp.stipulation;
      }
      // else {
      //   return null;
      // }
      this.handleOnceSingleOption(
        this.attachInsuranceType,
        this.ruleForm.policyPlanList,
        "insuranceTypeName"
      );
    },
    handleMainSelect(value, item) {
      let temp = this.primaryInsuranceType.find((el) => {
        return el.dictKey === value;
      });
      this.ruleForm.policyPlanList = [this.ruleForm.policyPlanList[0]];
      switch (temp.dictValue) {
        case "财产一切险主险":
          this.attachInsuranceType = this.attachData.filter((val) => {
            return val.dictKey > 100 && val.dictKey <= 200;
          });
          break;
        case "财产综合险主险":
          this.attachInsuranceType = this.attachData.filter((val) => {
            return val.dictKey > 200 && val.dictKey <= 300;
          });
          break;
        case "第三者责任险":
          this.attachInsuranceType = this.attachData.filter((val) => {
            return val.dictKey > 300 && val.dictKey <= 400;
          });
          break;
        case "起重机综合险":
          this.attachInsuranceType = this.attachData.filter((val) => {
            return val.dictKey > 400 && val.dictKey <= 500;
          });
          break;
        case "工程机械设备险":
          this.attachInsuranceType = this.attachData.filter((val) => {
            return val.dictKey > 200 && val.dictKey <= 300;
          });
          break;
        case "驾意险":
          this.attachInsuranceType = [];
          break;
      }
    },
    handleClickAnchor(v) {
      this.$refs["anchor" + v.index].scrollIntoView();
    },
    onSelected(e, type, index) {
      if (type == "province") {
        this.ruleForm[type] = e.province.value == "省" ? "" : e.province.value;
        this.$refs.province.clearValidate();
      } else if (type == "city") {
        this.ruleForm[type] = e.city.value == "市" ? "" : e.city.value;
        this.$refs.city.clearValidate();
      } else {
        this.ruleForm[type][index].province = e.province.value;
        this.ruleForm[type][index].city = e.city.value;
        this.ruleForm[type][index].county = e.area.value;
      }
    },
    handleDistpicker() {
      this.$refs["distpicker-box"].city();
    },
    handleAddressVal(e) {
      this.ruleForm.policySubjectList[this.policySubjectListIndex].address = e;
      this.isPositionDialog = false;
    },
    handleAddContent(type, data) {
      if (type === "policySubjectList") {
        if (this.ruleForm[type].length === 2)
          return this.$message.error("不能超过2个");
      }
      this.ruleForm[type].push(JSON.parse(JSON.stringify(data)));
    },
    handleDelContent(type, index, row) {
      this.ruleForm[type].splice(index, 1);
      if (type == "policyPlanList") {
        // key:201 拓展不计免赔
        this.attachInsuranceType.find((el) => {
          if (el.dictKey == row.insuranceType) {
            el.disabled = false;
          }
        });
        //this.InputcalculatePremium(row);
      }
    },
    handleSynchronization(type, index, item) {
      for (const key in this.ruleForm[type][index]) {
        this.ruleForm[type][index][key] = item[key];
      }
    },
    handleChangeProtagonist(ind) {
      for (
        let index = 0;
        index < this.ruleForm.acceptCompanyList.length;
        index++
      ) {
        if (index == ind) {
          this.ruleForm.acceptCompanyList[index].acceptPrimary = true;
        } else {
          this.ruleForm.acceptCompanyList[index].acceptPrimary = false;
        }
      }
    },
    handleAddClause() {
      // if (!this.attachInsuranceType.length) {
      //   this.$message.error('该主险暂无附加险');
      //   return false;
      // }
      this.ruleForm.policyPlanList.push({
        insuranceType: 1,
        insuranceTypeCode: "attachInsuranceType",
        insuranceTypeName: "",
        limitCompensation: "",
        premium: 0,
        rate: 0,
        rateType: 1,
        stipulation: "",
        sumInsured: 0,
        termConfigurationName: "",
        isNewAdd: true,
      });
    },
    handleSubmit() {
      this.dealAttachmentsName();
      return new Promise((resolve, reject) => {
        //   this.ruleForm.province = this.provinceList.find(item => {
        //     return item.dictKey == this.ruleForm.provinceId;
        //   }).dictValue;
        this.ruleForm.projectName =
          this.projectNameOptions.find((item) => {
            return item.dictKey == this.ruleForm.projectId;
          })?.dictValue || "";
          // createCorrectionType.includes(4)
        this.createCorrectionType.forEach(val=> {
          this.$refs[`ruleForm${val}`].validate(
            (valid, validObj) => {
              let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
              if (!ruleForm.approval) {
                this.$refs["approval"].$el.scrollIntoView();
                this.$message.error("请输入批文！");
                reject("请输入批文！");
                return;
              } else if (ruleForm.orderType == 5 && !ruleForm.accountName) {
                this.$refs["accountName"].$el.scrollIntoView();
                this.$message.error("请输入户名！");
                reject("请输入户名！");
                return;
              }
              ruleForm.startDate = ruleForm.startDate + " 00:00:00";
              ruleForm.endDate = ruleForm.endDate + " 23:59:59";
              ruleForm.policyPlanList = ruleForm.policyPlanList.map(
                (val, ind) => {
                  val.sort = ind;
                  return val;
                }
              );
              if (
                valid &&
                this.handleCheckout(ruleForm) &&
                this.createCorrectionType.includes('4')
              ) {
                //  &&
                // this.$route.meta.policyType == 1
                if (
                  (this.ruleForm.absoluteDeductibleCheckbox &&
                    this.ruleForm.absoluteDeductible) ||
                  (this.ruleForm.absoluteDeductible2Checkbox &&
                    this.ruleForm.absoluteDeductible2) ||
                  (this.ruleForm.absoluteDeductible3Checkbox &&
                    this.ruleForm.absoluteDeductible3)
                ) {
                  if (ruleForm.startDate) {
                    ruleForm.startDate = dayjs(ruleForm.startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    );
                    ruleForm.endDate = dayjs(ruleForm.endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    );
                  }
                  resolve(
                    Object.assign(
                      ruleForm,
                      {
                        orderType:
                          this.ruleForm.orderType == ""
                            ? this.orderType
                            : this.ruleForm.orderType,
                      },
                      this.uploadData
                    )
                  );
                } else {
                  // this.$message.error("请至少勾选一项每次事故绝对免赔额");
                  reject("请勾选和填写一项每次事故绝对免赔额");
                  this.$refs["absoluteDeductible"].scrollIntoView();
                  reject("校验未通过");
                  //   reject("点击提交必填项没有填写的话");
                }
              } else if (!valid) {
                let messageList = [];
                // this.$message.error('校验未通过');
                for (const key in validObj) {
                  messageList.push(validObj[key][0]);
                }
                if (messageList.length) {
                  this.$message.error(messageList[0].message);
                  this.$refs[messageList[0].field].$el.scrollIntoView();
                }else {reject("校验未通过");}
                throw Error('校验未通过');
              } else {
                resolve(
                  Object.assign(
                    ruleForm,
                    {
                      orderType:
                        this.ruleForm.orderType == ""
                          ? this.orderType
                          : this.ruleForm.orderType,
                    },
                    this.uploadData
                  )
                );
              }
            }
          );
        })
      });
    },
    handleCheckout(ruleForm) {
      let index = ruleForm.acceptCompanyList.findIndex(
        (val) => !val.companyName || !val.branch
      );
      if (
        this.handleDealWith(
          ruleForm.policyInsuredList,
          "被保险人",
          "policyInsuredList"
        ) &&
        this.handleDealWith(
          ruleForm.policyHolderList,
          "投保人",
          "policyHolderList"
        ) &&
        (this.$route.meta.policyType != 1
          ? true
          : this.handleDealWith(
              ruleForm.beneficiaryList,
              "受益人",
              "beneficiaryList"
            )) &&
        (!this.ruleForm.isSingle ||
          this.hadnleCheckTarget(
            ruleForm.policySubjectList,
            "policySubjectList"
          )) &&
        this.hadnleCheckGuarantee(ruleForm.policyPlanList, "policyPlanList")
      ) {
        if (index > -1) {
          this.$message.error(
            ruleForm.acceptCompanyList[index].companyName
              ? "选择分支机构"
              : "选择保险公司"
          );
          this.$refs["acceptCompanyList" + index][0].scrollIntoView();
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    hadnleCheckGuarantee(list, type) {
      if (this.$route.meta.policyType !== 1) return true;
      let falg = true;
      if (!list || !list.length) {
        this.$message.error("请选择保障计划");
        return false;
      }
      list.map((val, index) => {
        if (!falg) {
          throw new Error("校验错误");
        } else if (val.insuranceType === "") {
          falg = false;
          this.$message.error(
            `请输入第${index + 1}条保障计划中的险种（条款）名称`
          );
          this.$refs[type].scrollIntoView();
        } else if (val.sumInsured === "") {
          falg = false;
          this.$message.error(`请输入第${index + 1}条保障计划中的保额/限额`);
          this.$refs[type].scrollIntoView();
        } else if (val.limitCompensation === "") {
          falg = false;
          this.$message.error(`请输入第${index + 1}条保障计划中的每次赔偿限额`);
          this.$refs[type].scrollIntoView();
        } else if (val.rate === "") {
          falg = false;
          this.$message.error(`请输入第${index + 1}条保障计划中的年费率`);
          this.$refs[type].scrollIntoView();
        } else if (val.premium === "") {
          falg = false;
          this.$message.error(`请输入第${index + 1}条保障计划中的保费`);
          this.$refs[type].scrollIntoView();
        }
      });
      return falg;
    },
    handleDealWith(list, msg, type) {
      let falg = true;
      list.map((val, index) => {
        if (val.cardTime && val.cardTime.length) {
          list[index].cardStart = val.cardTime[0] || null;
          list[index].cardEnd = val.cardTime[1] || null;
        } else {
          list[index].cardStart = null;
          list[index].cardEnd = null;
        }
        if (!falg) {
          throw new Error("校验错误");
        } else if (!val.playersName) {
          falg = false;
          this.$message.error("请输入" + msg + "名称");
          this.$refs[type + index][0].scrollIntoView();
          //   } else if (!val.cardType) {
        } else if (
          !val.cardType &&
          val.cardType !== "0" &&
          val.cardType !== 0
        ) {
          falg = false;
          this.$message.error("请输入" + msg + "证件类型");
          this.$refs[type + index][0].scrollIntoView();
        } else if (!val.cardNo) {
          falg = false;
          this.$message.error("请输入" + msg + "证件号码");
          this.$refs[type + index][0].scrollIntoView();
        } else if (!val.province || !val.city) {
          falg = false;
          this.$message.error("请选择" + msg + "所在区域");
          this.$refs[type + index][0].scrollIntoView();
        }
        //   else if (!val.detailAddress && this.$route.meta.policyType != 3) {
        //     falg = false;
        //     this.$message.error("请输入" + msg + "详细地址");
        //     this.$refs[type + index][0].scrollIntoView();
        //   }
        else if (!val.contactName && this.$route.meta.policyType != 3) {
          falg = false;
          this.$message.error("请输入" + msg + "联系人姓名");
          this.$refs[type + index][0].scrollIntoView();
        } else if (!val.contactPhone && this.$route.meta.policyType != 3) {
          falg = false;
          this.$message.error("请输入" + msg + "联系电话");
          this.$refs[type + index][0].scrollIntoView();
        }
        //  else if (!val.cardTime || !val.cardTime.length) {
        //   falg = false;
        //   this.$message.error("请输入" + msg + "证件有效期");
        // }
      });
      return falg;
    },
    handleReverseDealWith(list, msg) {
      list.map((val, index) => {
        if (val.cardTime && val.cardTime.length) {
          list[index].cardStart = val.cardTime[0];
          list[index].cardEnd = val.cardTime[1];
        }
        if (val.cardStart && val.cardEnd) {
          list[index].cardTime = [val.cardStart, val.cardEnd];
        } else if (val.cardStart) {
          list[index].cardTime = [val.cardStart, ""];
          list[index].checked = true;
        }
      });
    },
    hadnleCheckTarget(list, type) {
      // !this.createCorrectionType.includes(2)
      if (this.$route.meta.policyType == 3 || !this.createCorrectionType.includes('2')) {
        return true;
      }
      let falg = true;
      if (!list || !list.length) {
        this.$message.error("请输入标的信息");
        return false;
      }
      list.map((val, index) => {
        if (!falg) {
          throw new Error("校验错误");
        } else if (val.brand === "") {
          falg = false;
          this.$message.error("请输入标的信息设备品牌");
          this.$refs[type + index][0].scrollIntoView();
        } else if (
          val.deviceType == "" &&
          val.deviceType !== "0" &&
          val.deviceType !== 0
        ) {
          falg = false;
          this.$message.error("请输入标的信息设备类型");
          this.$refs[type + index][0].scrollIntoView();
        } else if (val.equipmentType === "") {
          falg = false;
          this.$message.error("请输入标的信息设备型号");
          this.$refs[type + index][0].scrollIntoView();
        } else if (val.frameNo === "") {
          falg = false;
          this.$message.error("请输入标的信息车架号 (机身编号)");
          this.$refs[type + index][0].scrollIntoView();
        } else if (val.engineNo === "" && this.$route.meta.policyType == 2) {
          falg = false;
          this.$message.error("请输入标的信息发动机编号");
          this.$refs[type + index][0].scrollIntoView();
        } else if (
          val.purchasePrice === "" &&
          this.$route.meta.policyType === 1
        ) {
          falg = false;
          this.$message.error("请输入标的信息新设备购置价");
          this.$refs[type + index][0].scrollIntoView();
        } else if (val.productionDate === "") {
          falg = false;
          this.$message.error("请输入标的信息出厂日期");
          this.$refs[type + index][0].scrollIntoView();
        } else if (val.mainInsuranceAmount === "") {
          falg = false;
          this.$message.error("请输入标的信息主险保额");
          this.$refs[type + index][0].scrollIntoView();
        } else if (val.curbWeight === "") {
          falg = false;
          this.$message.error("请输入标的信息整备质量");
          this.$refs[type + index][0].scrollIntoView();
        } else if (val.origin === "" && this.$route.meta.policyType == 1) {
          falg = false;
          this.$message.error("请输入标的信息产地");
          this.$refs[type + index][0].scrollIntoView();
        }
      });
      return falg;
    },
    handleChangeApply(schemeData, culateData, type = 0) {
      // 换新的逻辑
      this.ruleForm.schemeName = schemeData.insureProgrammeName;
      this.newInsurancePlanFill(schemeData, type);
      let absoluteDeductibleData =
        this.pickAndChooseSomeIns(
          schemeData.insPolicyTermConfigurationRespList,
          [5]
        )[0] || {};
      let specialAgreementData =
        this.pickAndChooseSomeIns(
          schemeData.insPolicyTermConfigurationRespList,
          [2]
        )[0] || {};
      let extensionClauseData =
        this.pickAndChooseSomeIns(
          schemeData.insPolicyTermConfigurationRespList,
          [3]
        )[0] || {};
      let specialVersionData =
        this.pickAndChooseSomeIns(
          schemeData.insPolicyTermConfigurationRespList,
          [4]
        )[0] || {};
      let otherData = {
        absoluteDeductible:
          absoluteDeductibleData.extensionWithoutDeductibleOne,
        absoluteDeductible2:
          absoluteDeductibleData.extensionWithoutDeductibleTwo,
        absoluteDeductible3: absoluteDeductibleData.extensionWithoutDeductible,
        absoluteDeductibleCheckbox: schemeData.isExtensionWithoutDeductible,
        absoluteDeductibleCheckbox2: schemeData.isExtensionWithoutDeductibleTwo,
        absoluteDeductibleCheckbox3: schemeData.isExtensionWithoutDeductible,
        specialAgreement: specialAgreementData?.illustrate,
        specialVersion: specialVersionData?.illustrate,
        extensionClause: extensionClauseData?.illustrate,
      };
      this.ruleForm = { ...this.ruleForm, ...otherData };
      this.setPrimaryInsuranceTypeSumInsured(schemeData);
      return;
      // if (!this.permissions.policyPlanList) {
      //   return false;
      // }
      // let applyData = this.options.filter((val) => val.schemeName == e);
      // this.ruleForm.extensionClause = applyData[0].freeExtensionTerms;
      // this.ruleForm.specialAgreement = applyData[0].specialAgreement;
      // this.ruleForm.specialVersion = applyData[0].specialVersion;
      // this.ruleForm.absoluteDeductible = applyData[0].deductibleOne;
      // this.ruleForm.absoluteDeductible2 = applyData[0].deductibleTwo;
      // this.ruleForm.absoluteDeductible3 = applyData[0].deductibleExpanded;
      // this.ruleForm.absoluteDeductibleCheckbox =
      //   applyData[0].deductibleOneFlag == 1 ? true : false;
      // this.ruleForm.absoluteDeductible2Checkbox =
      //   applyData[0].deductibleTwoFlag == 1 ? true : false;
      // this.ruleForm.absoluteDeductible3Checkbox =
      //   applyData[0].deductibleExpandedFlag == 1 ? true : false;
      // if (applyData[0].policySafeguardPlanList.length > 0) {
      //   switch (applyData[0].policySafeguardPlanList[0].nameOfInsuranceClause) {
      //     case "财产一切险主险":
      //       this.attachInsuranceType = this.attachData.filter((val) => {
      //         return val.dictKey > 100 && val.dictKey <= 200;
      //       });
      //       break;
      //     case "财产综合险主险":
      //       this.attachInsuranceType = this.attachData.filter((val) => {
      //         return val.dictKey > 200 && val.dictKey <= 300;
      //       });
      //       break;
      //     case "第三者责任险":
      //       this.attachInsuranceType = this.attachData.filter((val) => {
      //         return val.dictKey > 300 && val.dictKey <= 400;
      //       });
      //       break;
      //     case "起重机综合险":
      //       this.attachInsuranceType = this.attachData.filter((val) => {
      //         return val.dictKey > 400 && val.dictKey <= 500;
      //       });
      //       break;
      //     case "工程机械设备险":
      //       this.attachInsuranceType = this.attachData.filter((val) => {
      //         return val.dictKey > 200 && val.dictKey <= 300;
      //       });
      //       break;
      //     case "驾意险":
      //       this.attachInsuranceType = [];
      //       break;
      //   }
      // }
      // this.ruleForm.policyPlanList = applyData[0].policySafeguardPlanList.map(
      //   (val, ind) => {
      //     return {
      //       insuranceTypeName: val.nameOfInsuranceClause,
      //       insuranceType: Number(val.typeOfInsuranceClause),
      //       sumInsured: val.insuranceAmount,
      //       limitCompensation: val.maximumIndemnityNorm,
      //       insuranceTypeCode: ind
      //         ? "attachInsuranceType"
      //         : "primaryInsuranceType",
      //       rate:
      //         val.annualFeeRate.indexOf("%") > -1
      //           ? val.annualFeeRate.split("%")[0]
      //           : val.annualFeeRate.split("￥")[0],
      //       // rateType: val.annualFeeRate.indexOf("%") > -1 ? 1 : 2,
      //       rateType: 1,
      //       premium: val.premium,
      //       stipulation: this.handleAttachSelect(
      //         Number(val.typeOfInsuranceClause),
      //         val
      //       ),
      //     };
      //   }
      // );
      // this.setPrimaryInsuranceTypeSumInsured();
      // this.handleOnceSingleOption(this.attachInsuranceType, this.ruleForm.policyPlanList, 'insuranceTypeName');
      // this.temporaryVal = "";
    },
    handleDropdownClick(e, ind) {
      this.ruleForm.policyPlanList[ind].rateType = e;
      if (item.rateType) {
        item.premium = (
          (Number(item.sumInsured) || 0) *
          (Number(item.rate) || 0) *
          0.01
        ).toFixed(2);
      } else {
        item.premium = (Number(item.rate) || 0).toFixed(2);
      }
    },
    handleChangeSubject(e) {
      const oldSubjectList =
        this.ruleForm.policySubjectList &&
        this.ruleForm.policySubjectList.length
          ? JSON.stringify(this.ruleForm.policySubjectList)
          : null;
      if (this.oldSubjectList && this.oldSubjectList.length) {
        this.ruleForm.policySubjectList = JSON.parse(this.oldSubjectList);
      } else if (e == 1) {
        this.ruleForm.policySubjectList = [
          {
            address: "",
            addressLonlat: "",
            agency: "",
            brand: "",
            brandName: "",
            curbWeight: "",
            deviceType: "",
            deviceTypeName: "",
            engineNo: "",
            equipmentType: "",
            frameNo: "",
            itemNo: "",
            mainInsuranceAmount: "",
            origin: 1,
            premium: "",
            productionDate: "",
            purchasePrice: "",
            workTime: "",
            yearlyDepreciation: "",
            vehicleAccredit: false,
          },
        ];
      } else {
        this.ruleForm.policySubjectList = [];
      }
      this.oldSubjectList = oldSubjectList;
    },
    handleChangeDetails(v) {
      this.isAssociationDialog = false;
      let ruleForm = JSON.parse(JSON.stringify(v));
      ruleForm.autoComputeFlag === undefined
        ? (ruleForm.autoComputeFlag = true)
        : "";
      ruleForm.startDate
        ? (ruleForm.startDate = ruleForm.startDate.split(" ")[0])
        : "";
      ruleForm.endDate
        ? (ruleForm.endDate = ruleForm.endDate.split(" ")[0])
        : "";
      if (
        ruleForm.startDate &&
        ruleForm.startDate.split(" ")[1] &&
        ruleForm.startDate.split(" ")[1] == "00:00:00"
      ) {
        this.startType = 2;
      } else {
        this.startType = 1;
      }
      this.handleReverseDealWith(ruleForm.policyInsuredList);
      this.handleReverseDealWith(ruleForm.policyHolderList);
      this.handleReverseDealWith(ruleForm.beneficiaryList);
      this.ruleForm = ruleForm;
    },
    handleChangeSubjectList(e) {
      this.ruleForm.policySubjectList = e;
    },
    handleDownloadSubjectList() {
      downloadSubject({
        orderId: this.ruleForm.id,
        orderType: this.ruleForm.orderType == 3 ? 3 : 2,
      });
    },
    submitData(type = "save") {
      //return Object.assign({orderType: this.ruleForm.orderType==-1?this.orderType:this.ruleForm.orderType,},diffObject(this.ruleForm,this.detailData))
      this.ruleForm.startDate = dayjs(this.ruleForm.startDate).format(
        "YYYY-MM-DD 00:00:00"
      );
      this.ruleForm.endDate = dayjs(this.ruleForm.endDate).format(
        "YYYY-MM-DD 23:59:59"
      );
      this.dealAttachmentsName();
      return {
        data: Object.assign(
          this.ruleForm,
          {
            orderType:
              this.ruleForm.orderType == ""
                ? this.orderType
                : this.ruleForm.orderType,
          },
          this.uploadData
        ),
        type,
      };
    },
    // 格式化限制数字文本框输入，只能数字或者两位小数
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
      if (key === "sumInsured") {
        tiem.limitCompensation = tiem.sumInsured;
      }
      if (
        (key === "sumInsured" || key === "rate") &&
        (tiem.sumInsured || tiem.rate)
      ) {
        if (tiem.rateType) {
          tiem.premium = (
            (Number(tiem.sumInsured) || 0) *
            (Number(tiem.rate) || 0) *
            0.01
          ).toFixed(2);
        } else {
          tiem.premium = (Number(tiem.rate) || 0).toFixed(2);
        }
      }
      //this.InputcalculatePremium(tiem);
    },
    // 格式化限制数字和字母
    input_text(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和字母以外的字符
      tiem[key] = e.replace(/[^\w]/g, "");
    },
    InputcalculatePremium(data) {
      if (this.ruleForm.schemeName == "平安汽车吊承保方案") {
        let otherSum = 0;
        let spIndex = -1;
        this.ruleForm.policyPlanList.forEach((item, index) => {
          //扩展不计免赔 201
          //  if (item.insuranceType != 201) {
          if (item.insuranceTypeName != "扩展不计免赔") {
            otherSum += Number(item.premium);
          } else {
            spIndex = index;
          }
        });
        //   if (spIndex != -1 && data.insuranceType != 201) {
        if (
          spIndex != -1 &&
          (!data || data.insuranceTypeName != "扩展不计免赔")
        ) {
          this.ruleForm.policyPlanList[spIndex].premium = otherSum.toFixed(2);
        }
        //   if (spIndex != -1) {
        //    this.ruleForm.policyPlanList[spIndex].premium = (this.ruleForm.policyPlanList[spIndex].sumInsured * this.ruleForm.policyPlanList[spIndex].rate * 0.01).toFixed(2);
        //   }
      }
    },
    handleIdentify(type, key, index) {
      if (type == 1) {
        uploadFile({}).then((file) => {
          fileToBase64(file).then((base64) => {
            let formData = new FormData();
            formData.append("imagBase64", base64.split(",")[1]);
            const loading = this.$loading({
              lock: true,
              text: "识别中...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            businessLicense(formData)
              .then((res) => {
                loading.close();

                if (this.$route.meta.policyType == 2) {
                  let fileList = {
                    raw: file,
                    name: file.name,
                  };
                  let type = file.name.split(".");
                  let identityOss = {
                    fileName: fileList.name,
                    fileType: type[type.length - 1],
                  };
                  OSSDirectPass(fileList).then((ossURL) => {
                    if (!this.ruleForm.attachments) {
                      this.ruleForm.attachments = {
                        yyzz: [],
                      };
                    } else if (!this.ruleForm.attachments.yyzz) {
                      this.ruleForm.attachments.yyzz = [];
                    }
                    this.ruleForm.attachments.yyzz = [
                      ...this.ruleForm.attachments.yyzz,
                      {
                        attachmentType: "yyzz",
                        fileName: identityOss.fileName,
                        fileType: identityOss.fileType,
                        url: ossURL,
                      },
                    ];
                    this.$forceUpdate();
                  });
                }
                // console.log(res);
                this.ruleForm[key][index].cardNo = res.data.creditCode || "";
                this.ruleForm[key][index].playersName =
                  res.data.companyName || "";
                this.ruleForm[key][index].county = res.data.district || "";
                this.ruleForm[key][index].city = res.data.city || "";
                this.ruleForm[key][index].province = res.data.province || "";
                this.ruleForm[key][index].detailAddress =
                  res.data.businessAddress || "";

                this.ruleForm[key][index].cardTime = ["", ""];
                this.ruleForm[key][index].cardStart = "";
                this.ruleForm[key][index].cardEnd = "";
                this.ruleForm[key][index].checked = false;
                this.ruleForm[key][index].cardNo && this.AssignmentObjType
                  ? this.handleWeightCheck(
                      this.ruleForm[key][index],
                      this.AssignmentObjType
                    )
                  : "";
                if (
                  dayjs(res.data.validPeriodBegin).format("YYYY-MM-DD") !=
                  "Invalid Date"
                ) {
                  this.ruleForm[key][index].cardTime[0] =
                    res.data.validPeriodBegin;
                  this.ruleForm[key][index].cardStart =
                    res.data.validPeriodBegin;
                }
                if (res.data.validPeriod == "长期") {
                  this.ruleForm[key][index].checked = true;
                  if (
                    dayjs(res.data.validPeriodBegin).format("YYYY-MM-DD") !=
                    "Invalid Date"
                  ) {
                    this.ruleForm[key][index].cardTime[0] = dayjs(
                      res.data.validPeriodBegin
                    ).format("YYYY-MM-DD");
                    this.ruleForm[key][index].cardStart = dayjs(
                      res.data.validPeriodBegin
                    ).format("YYYY-MM-DD");
                  } else {
                    this.ruleForm[key][index].cardTime[0] = "2000-01-01";
                    this.ruleForm[key][index].cardStart = "2000-01-01";
                  }
                } else if (
                  dayjs(res.data.validPeriodEnd).format("YYYY-MM-DD") !=
                  "Invalid Date"
                ) {
                  this.ruleForm[key][index].cardTime[1] =
                    res.data.validPeriodEnd;
                  this.ruleForm[key][index].cardEnd = res.data.validPeriodEnd;
                }
                //  this.ruleForm[key][index].cardTime =
                //    res.data.validPeriod == "长期"
                //      ? [res.data.validPeriodBegin ? res.data.validPeriodBegin : "2000-01-01", ""]
                //      : [res.data.validPeriodBegin, res.data.validPeriodEnd];
                //  this.ruleForm[key][index].cardStart = res.data.validPeriodBegin ? res.data.validPeriodBegin : res.data.validPeriod == "长期" ? '2000-01-01' : '';
                //  this.ruleForm[key][index].cardEnd = res.data.validPeriodEnd;
                //  res.data.validPeriod == "长期"
                //    ? (this.ruleForm[key][index].checked = true)
                //    : "";
              })
              .catch((err) => {
                loading.close();
              });
          });
        });
      } else {
        this.isIdentityCard = true;
        this.AssignmentObj = this.ruleForm[key][index];
      }
    },
    handleIdentifySuccess(e) {
      e.sex ? (this.AssignmentObj.sex = e.sex == "男" ? true : false) : "";
      e.idnumber ? (this.AssignmentObj.cardNo = e.idnumber) : "";
      e.name ? (this.AssignmentObj.playersName = e.name) : "";
      e.province ? (this.AssignmentObj.province = e.province) : "";
      e.city ? (this.AssignmentObj.city = e.city) : "";
      e.district ? (this.AssignmentObj.county = e.district) : "";
      e.address ? (this.AssignmentObj.detailAddress = e.address) : "";
      this.AssignmentObj.cardNo && this.AssignmentObjType
        ? this.handleWeightCheck(this.AssignmentObj, this.AssignmentObjType)
        : "";
      if (e.identityOss || e.identityOss2) {
        if (!this.ruleForm.attachments) {
          this.ruleForm.attachments = {
            yyzz: [],
          };
        } else if (!this.ruleForm.attachments.yyzz) {
          this.ruleForm.attachments.yyzz = [];
        }
        let identityOss = [];
        if (e.identityOss) {
          identityOss.push({
            attachmentType: "yyzz",
            fileName: e.identityOss.fileName,
            fileType: e.identityOss.fileType,
            url: e.identityOss.url,
          });
        }
        if (e.identityOss2) {
          identityOss.push({
            attachmentType: "yyzz",
            fileName: e.identityOss2.fileName,
            fileType: e.identityOss2.fileType,
            url: e.identityOss2.url,
          });
        }

        this.ruleForm.attachments.yyzz = [
          ...this.ruleForm.attachments.yyzz,
          ...identityOss,
        ];
      }
      e.validPeriod
        ? (this.AssignmentObj.cardTime = [e.validPeriodBegin, e.validPeriodEnd])
        : "";
      e.validPeriod
        ? ((this.AssignmentObj.cardStart = e.validPeriodBegin),
          (this.AssignmentObj.cardEnd = e.validPeriodEnd))
        : "";
      e.birthDate ? (this.AssignmentObj.birthday = e.birthDate) : "";
      this.AssignmentObj = null;
    },
    handleUpRadio(e) {
      this.$forceUpdate();
    },
    onPreview() {
      // this.detailData
      if (this.detailData.ep.fileType == "pdf") {
        window.open(this.detailData.ep.url, "_blank");
      } else {
        this.$refs["img-box"].showViewer = true;
        this.srcList = [this.detailData.ep.url];
      }
    },
    onDownload() {
      if (this.detailData.ep.fileType == "pdf") {
        this.pdfDownlad(this.detailData.ep.url, this.detailData.ep.fileName);
      } else {
        this.downloadImage(this.detailData.ep.url, this.detailData.ep.fileName);
      }
    },
    downloadImage(url, downloadName) {
      const link = document.createElement("a");
      link.setAttribute("download", downloadName);
      const image = new Image();
      // 添加时间戳，防止浏览器缓存图片
      image.src = url + "?timestamp=" + new Date().getTime();
      // 设置 crossOrigin 属性，解决图片跨域报错
      image.setAttribute("crossOrigin", "Anonymous");
      image.onload = () => {
        link.href = this.getBase64Image(image);
        link.click();
      };
    },
    pdfDownlad(url, fileName) {
      let xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.setRequestHeader("Content-Type", `application/pdf`);
      xhr.responseType = "blob";
      let that = this;
      xhr.onload = function () {
        if (this.status == 200) {
          //接受二进制文件流
          var blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
      // return dataURL.replace("data:image/png;base64,", "");
    },
    datePickerChange(e, type) {
      if (
        type == "endDate" &&
        this.ruleForm.startDate &&
        new Date(this.ruleForm.startDate) > new Date(e)
      ) {
        this.setDatePicker("startDate", this.ruleForm.startDate);
        this.$message.error("结束时间不能小于开始时间");
      } else if (!this.ruleForm.endDate || !this.ruleForm.startDate) {
        this.setDatePicker(type, e);
      } else if (type == "startDate" && this.ruleForm.endDate) {
        this.setDatePicker("startDate", this.ruleForm.startDate);
      }
      // else if (
      //   type == "startDate" &&
      //   this.ruleForm.endDate &&
      //   new Date(e) > new Date(this.ruleForm.endDate)
      // ) {
      //   this.setDatePicker("endDate", this.ruleForm.endDate);
      //   this.$message.error("开始时间不能大于结束时间");
      // }
    },
    setDatePicker(type, date) {
      if (!date) {
        return false;
      } else if (type == "endDate") {
        if (this.startType == 1) {
          this.ruleForm.startDate = dayjs(date)
            .subtract(1, "year")
            .format("YYYY-MM-DD hh:mm:ss");
        } else {
          this.ruleForm.startDate = dayjs(
            dayjs(date).subtract(1, "year").format("YYYY-MM-DD hh:mm:ss")
          )
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        }
      } else if (type == "startDate") {
        if (this.startType == 1) {
          this.ruleForm.endDate = dayjs(date)
            .add(1, "year")
            .format("YYYY-MM-DD");
        } else {
          this.ruleForm.endDate = dayjs(
            dayjs(date).add(1, "year").format("YYYY-MM-DD")
          )
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        }
      }
    },
    handleCheckboxChange(e, type) {
      if (type == 1) {
        this.ruleForm.absoluteDeductible2Checkbox = false;
        this.ruleForm.absoluteDeductible3Checkbox = false;
      } else if (type == 2) {
        this.ruleForm.absoluteDeductibleCheckbox = false;
        this.ruleForm.absoluteDeductible3Checkbox = false;
      } else if (type == 3) {
        this.ruleForm.absoluteDeductibleCheckbox = false;
        this.ruleForm.absoluteDeductible2Checkbox = false;
      }
      this.$forceUpdate();
    },
    handleChangeSalesman(id, name) {
      this.ruleForm.salesmanName = name;
    },
    handleChangeCardTime(e, ind, type) {
      if (e && e.length) {
        this.ruleForm[type][ind].cardStart = e[0];
        this.ruleForm[type][ind].cardEnd = e[1];
      } else {
        this.ruleForm[type][ind].cardStart = "";
        this.ruleForm[type][ind].cardEnd = "";
        this.ruleForm[type][ind].cardTime = "";
        this.ruleForm[type][ind].checked = false;
      }
    },
    handleChangeEndTime(e, ind, list, key) {
      if (
        this.ruleForm[list][ind].cardTime &&
        this.ruleForm[list][ind].cardTime.length > 1
      ) {
        if (
          key &&
          this.ruleForm[list][ind].cardTime[0] &&
          this.ruleForm[list][ind].cardTime[0] > e
        ) {
          this.$message.error("证件开始日期不能大于结束日期！");
          this.ruleForm[list][ind].cardEnd = "";
          return false;
        }
        this.ruleForm[list][ind].cardTime[key] = e;
      } else if (key) {
        this.ruleForm[list][ind].cardTime = ["", e];
      } else {
        this.ruleForm[list][ind].cardTime = [e, ""];
      }
      this.$forceUpdate();
    },
    handleLongTime(v, item) {
      v
        ? ((item.cardEnd = ""),
          item.cardTime && item.cardTime[1] ? (item.cardTime[1] = "") : "")
        : (item.checked = false);
      this.$forceUpdate();
    },
    handleChangeRecorder(e) {
      let obj = this.userOptions.filter((val) => val.userId == e)[0];
      this.ruleForm.recorderName = obj.userName;
    },
    changeValidateField(type) {
      this.$refs["ruleForm"].validateField(type);
    },
    async querySearch(queryString, cb) {
      try {
        const { data } = await selectPlayers({
          playerName: queryString,
          orderType: 2,
          dataType: this.queryDataType,
        });
        let cbList =
          data && data.length
            ? data.map((val) => {
                val.value = val.playersName;
                return val;
              })
            : [];
        cb(cbList);
      } catch (error) {
        cb([]);
      }
    },
    changeQuerySearch(e, type, item) {
      this.queryDataType = type;
      item.contactName = e;
      this.handleUpRadio();
    },
    async handleSelect(e, type, index) {
      try {
        const { data } = await selectPlayerById({ id: e.id });
        //   data.cardTime = [data.startDate || '', data.endDate || ''];
        //   this.ruleForm[type][index] = data;
        this.ruleForm[type].splice(index, 1, data);
        this.$forceUpdate();
      } catch (error) {}
    },
    setPrimaryInsuranceTypeSumInsured(list) {
      let num = 0;
      if (
        this.ruleForm.policySubjectList &&
        this.ruleForm.policySubjectList.length
      ) {
        this.ruleForm.policySubjectList.map((val) => {
          val.mainInsuranceAmount
            ? (num += Number(val.mainInsuranceAmount))
            : "";
        });
      }
      // if (this.ruleForm.policyPlanList && this.ruleForm.policyPlanList.length > 0 && (this.policySubjectNum !== num || !list)) {
      //   this.policySubjectNum = num;
      //   let index = this.ruleForm.policyPlanList.findIndex(val => val.insuranceTypeCode == "primaryInsuranceType");
      //   if (this.ruleForm.policyPlanList[index] && this.ruleForm.policyPlanList[index].insuranceType) {
      //     this.ruleForm.policyPlanList[index].sumInsured = num.toFixed(2);
      //     this.ruleForm.policyPlanList[index].limitCompensation = num.toFixed(2);
      //     if (this.ruleForm.policyPlanList[index].rate &&
      //       this.ruleForm.policyPlanList[index].rateType === 1
      //     ) {
      //       this.ruleForm.policyPlanList[index].premium = (this.ruleForm.policyPlanList[index].sumInsured * this.ruleForm.policyPlanList[index].rate * 0.01).toFixed(2);
      //     }
      //     //this.InputcalculatePremium(this.ruleForm.policyPlanList[index]);
      //   }
      //   this.$forceUpdate();
      // }
      if (this.ruleForm?.policyPlanList.length) {
        if (this.ruleForm.policyPlanList[0].insuranceType === 0) {
          this.ruleForm.policyPlanList[0].sumInsured = num.toFixed(2);
        }
      }
    },
    handleOnceSingleOption(optionArr, selectArr, prop) {
      let drawOutOptionArr = [];
      for (let i = 0; i < selectArr.length; i++) {
        for (let index = 0; index < optionArr.length; index++) {
          if (optionArr[index].dictValue == selectArr[i][prop]) {
            this.$set(optionArr[index], "disabled", true);
            optionArr[index].index = index;
            drawOutOptionArr.push(optionArr[index]);
            optionArr.splice(index, 1);
            break;
          } else {
            this.$set(optionArr[index], "disabled", false);
          }
        }
      }
      drawOutOptionArr.forEach((item) => {
        optionArr.splice(item.index, 0, item);
      });
      this.$forceUpdate();
    },
    dealAttachmentsName() {
      this.ruleForm.attachmentType = [];
      Object.keys(this.ruleForm.attachments).forEach((key) => {
        this.ruleForm.attachmentType.push(key);
      });
      this.ruleForm.attachmentType = this.ruleForm.attachmentType.toString();
    },
    handleInsuredCategory(e, list, key, value, data, dataKey) {
      const findData = list.find((val) => val[key] == e);
      data[dataKey] = e ? findData[value] : "";
    },
    //  证件号查重
    handleWeightCheck(v, dataType) {
      insEnquiryArchiveRepeat({
        cardNo: v.cardNo,
        orderType: 2,
        salesmanId: this.ruleForm.salesmanId,
        dataType,
      })
        .then((res) => {
          if (res.data) {
            v.check = true;
          } else {
            v.check = false;
          }
        })
        .catch((err) => {
          v.check = false;
        });
    },
    lookRepetition(v, dataType) {
      this.repetitionHistoryForm = {
        cardNo: v.cardNo,
        salesmanId: this.ruleForm.salesmanId,
        dataType,
        orderType: 2,
      };
      this.repetitionHistoryVisible = true;
    },
    handleChangeCompany(e, index) {
      let data = this.insuranceCompany.find((val) => val.dictKey == e);
      if (data && data.remark) {
        this.ruleForm.acceptCompanyList[index].insuranceBranch =
          data.remark.split(",");
      } else {
        this.ruleForm.acceptCompanyList[index].insuranceBranch = [];
      }
      this.$forceUpdate();
    },
    handleChangBrand(e, item) {
      let data = this.vehicleBrand.find((v) => v.dictKey == e);
      item.brandName = data.dictValue;
    },
    /////////////////////
    //承保方案20230802
    ////数据类型(0：主险、1：附加险、2：特别约定、3：免费拓展条款、4：特别说明、5:每次事故绝对免赔额)
    //数据分类
    sortInsuranceByType() {
      let sort = [
        {
          type: 0,
          dictName: "mainInsuranceDict",
        },
        {
          type: 1,
          dictName: "additionInsuranceDict",
        },
        {
          type: 2,
          dictName: "",
        },
        {
          type: 3,
          dictName: "",
        },
        {
          type: 4,
          dictName: "",
        },
        {
          type: 5,
          dictName: "",
        },
      ];
    },
    //寻找最大费率
    findMaxRateByUnit(arry = [], unit = [0, 1]) {
      // 0 %,1元
      if (!arry.length) return { rate: 0, rateUnit: "" };
      let targetArry = [];
      for (let i = 0; i < unit.length; i++) {
        let innerArry = arry.filter((item) => {
          return item.rateUnit === unit[i];
        });
        if (innerArry.length) {
          targetArry = innerArry;
          break;
        }
      }
      let max = 0;
      let rateUnit = "";
      let compensationAmount = 0;
      for (let i = 0; i < targetArry.length; i++) {
        if (targetArry[i].rate > max) {
          max = targetArry[i].rate;
          rateUnit = targetArry[i].rateUnit;
          compensationAmount = targetArry[i].compensationAmount;
        }
      }
      return {
        rate: max,
        rateUnit: rateUnit,
        compensationAmount: compensationAmount,
      };
    },
    mapNewPlanToOld(arry = []) {
      let res = [];
      res = arry.map((ins) => {
        return {
          id: ins.id,
          //取新的dataType
          insuranceType: ins.dataType,
          insuranceTypeCode: ins.dataType
            ? "attachInsuranceType"
            : "primaryInsuranceType",
          insuranceTypeName: ins.id,
          termConfigurationName: ins.termConfigurationName,
          limitCompensation: this.findMaxRateByUnit(
            ins.insPolicyInsuranceTypeAppendRespList
          ).compensationAmount,
          premium: "",
          //取最大的费率
          rate: this.findMaxRateByUnit(ins.insPolicyInsuranceTypeAppendRespList)
            .rate,
          //直接取rateUnit
          rateType: this.findMaxRateByUnit(
            ins.insPolicyInsuranceTypeAppendRespList
          ).rateUnit,
          //直接取illustrate
          stipulation: ins.illustrate,
          sumInsured: "",
          isNewAdd: ins.isNewAdd || false,
        };
      });
      return res;
    },
    //挑选某些条款
    pickAndChooseSomeIns(arr = [], pickType = [], parm = "dataType") {
      if (!arr.length || !pickType.length) {
        return [];
      } else {
        return arr.filter((item) => {
          return pickType.includes(item[parm]);
        });
      }
    },
    //新的承保方案数据填充
    newInsurancePlanFill(data, type = 0) {
      // 0 新询价单承保方案填充 1 测算填充
      let mapRes;
      mapRes = this.mapNewPlanToOld(data.insPolicyTermConfigurationRespList);
      this.ruleForm.policyPlanList = this.pickAndChooseSomeIns(
        mapRes,
        [0, 1],
        "insuranceType"
      );
    },
    handleInsuranceSelect(e, item, index) {
      let ins = this.primaryAddAttachInsuranceTypeList.find((it) => {
        return it.id == e;
      });
      let data = ins;
      data.isNewAdd = true;
      this.ruleForm.policyPlanList.splice(
        index,
        1,
        this.mapNewPlanToOld([data])[0]
      );
      console.log(item);
      //item.insuranceTypeName = data.id;
    },
    //计算短期费率
    calculationShortTermRates(mon = 0) {
      //9-12月递减 5n
      let rate = 0;
      let month = mon;
      if (!mon) month = 0;
      let years = Math.floor(month / 12);
      month = month % 12;
      month = Math.ceil(month);
      if (month > 8) {
        let interval = month - 8;
        rate = years + (month * 0.1 - 0.05 * interval);
      } else {
        rate = years + month * 0.1;
      }
      return rate;
    },
    clickRadio(i) {
      this.startType = "";
      this.ruleForm.endDate = "";
    },
    getNameByDictKey(dict, key, keyName, toName, ValueName, type) {
      if (type == "bindUserId") {
        this.ruleForm.upstreamAgentId = "";
        this.ruleForm.upstreamAgentName = "";
      }
      let res =
        this[dict].find((item) => {
          return item[keyName] === key;
        }) || null;
      if (res) {
        this.ruleForm[toName] = res[ValueName];
      } else {
        this.ruleForm[toName] = "";
      }
    },
    getInsuranceCompanyClauseByCategoryAndCom(comValue) {
      if (comValue) {
        getInsureProgrammeByCategory({
          category: this.$route.meta.policyType,
          companyValue: comValue,
          companyCode: "insuranceCompany",
        }).then((res) => {
          this.options = res.data;
        });
      } else {
        getInsureProgrammeByCategory({
          category: this.$route.meta.policyType,
        }).then((res) => {
          this.options = res.data;
        });
      }
    },
    //获取主承保公司
    getMainInsCom() {
      let mianCom = this.ruleForm.acceptCompanyList.find((item) => {
        return item.acceptPrimary;
      });
      return mianCom ? mianCom.company : "";
    },
    // 标的证件识别
    handleIdentifySubjectCard(data) {
      let mapkeys = {
        face: [
          ["engineNumber", "engineNo"],
          ["licensePlateNumber", "plate"],
          ["vinCode", "frameNo"],
          ["modelBrand", "brandName"],
          ["modelNumber", "equipmentType"],
        ],
        back: [
          ["curbWeight", "curbWeight"],
          ["licensePlateNumber", "plate"],
        ],
      };
      let formData = new FormData();
      data.face && formData.append("faceFile", data.faceRaw);
      data.back && formData.append("backFile", data.backRaw);
      certificateObject(formData).then((res) => {
        this.subjectRecognizeShow = false;
        for (const key in mapkeys) {
          mapkeys[key].forEach((k) => {
            if (k[0] == "curbWeight") {
              this.subjectRecognizeObj[k[1]] = res?.data[key][k[0]]?.replace(
                /\D/g,
                ""
              );
            } else if (k[0] == "modelBrand") {
              let brandKey = this.findVehicleBrandAndAdd(res?.data[key][k[0]]);
              if (brandKey == "") {
                // this.vehicleBrand.push({
                //   dictValue: res?.data[key][k[0]],
                //   dictKey: -2,
                // });
                this.subjectRecognizeObj.brand = -2;
              } else {
                this.subjectRecognizeObj.brand = brandKey;
              }
              this.subjectRecognizeObj[k[1]] = res?.data[key][k[0]];
            } else {
              this.subjectRecognizeObj[k[1]] = res.data[key][k[0]];
            }
          });
        }
        this.$forceUpdate();
      });
    },
    findVehicleBrandAndAdd(name) {
      let obj = this.vehicleBrand.find((item) => {
        return item.dictValue == name;
      });
      if (!obj?.dictKey) {
        this.vehicleBrand.push({
          dictValue: name,
          // -2 辅助回显
          dictKey: -2,
        });
      }
      return obj?.dictKey || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}
.container-box {
  padding: 18px 12px 0;
  border-top: 2px dashed #dedede;
  .personnel_repetition {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    padding: 8px 12px;
    margin-bottom: 24px;
    background: #cce6ff;
    border-radius: 8px;
    &_details {
      color: #0080ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.ep-b {
  font-size: 14px;
  color: #0080ff;
  margin-left: 24px;
  cursor: pointer;
  border-bottom: 1px solid #0080ff;
}
.policy-ep-img {
  width: 20px;
  height: 20px;
  margin-right: 14px;
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .merge-date {
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 3px 10px;
    > span {
      display: block;
      text-align: center;
      width: 20px;
    }
    .merge-date {
      flex: 1;
      text-align: center;
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  .el-autocomplete {
    width: 100%;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  ::v-deep .el-form-item {
    width: 30%;
    .el-form-item__content {
      width: 70%;
    }
  }
  @media (max-width: 1500px) {
    ::v-deep .el-form-item {
      width: 45%;
      .el-form-item__content {
        width: 85%;
      }
    }
  }
  @media (max-width: 1000px) {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 90%;
      }
    }
  }
  .plan-table {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 100%;
      }
      .el-icon--right {
        margin-left: 0;
      }
    }
  }
  ::v-deep .el-date-editor {
    width: 100%;
  }
  .input-customization {
    ::v-deep .el-input__inner {
      padding-right: 72px;
    }
  }
  .certificate-label {
    ::v-deep .el-form-item__label {
      width: 70%;
    }
    .certificate-box {
      display: flex;
      justify-content: space-between;
      &-right {
        display: flex;
        align-items: center;
        .example {
          font-size: 12px;
          color: #0080ff;
          margin-right: 8px;
          cursor: pointer;
        }
        .label-button {
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          padding: 0 6px;
          color: #ffaa00;
          background: rgba(255, 170, 0, 0.16);
          border-radius: 4px;
          border: 1px solid #ffaa00;
          cursor: pointer;
        }
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .insurance-clause {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 1000px;
    color: #00bc0d;
    font-size: 14px;
    border-top: none;
    border: 1px solid #ebeef5;
    cursor: pointer;
  }
  .input-position {
    .input-suffix {
      position: relative;
      width: 44px;
      height: 100%;
      border-left: 1px solid #dcdfe6;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
    ::v-deep .el-input__inner {
      padding-right: 48px;
    }
  }
}
.clause-title {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.list-item {
  &:first-child {
    border: none;
    padding-top: 0;
  }
  padding-top: 10px;
  border-top: 1px dashed #999;

  position: relative;
  .right-btn {
    position: absolute;
    right: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .recognize-btn {
    background: rgba(252, 161, 24, 0.1);
    border-radius: 4px 4px 4px 4px;
    border: 1px solid rgba(252, 161, 24, 0.2);
    font-weight: 400;
    color: #fca118;
    padding: 6px !important;
  }
  .del-button {
    position: absolute;
    right: 0;
  }
}
</style>
<style lang="scss">
.policy-label-style {
  font-size: 14px;
  color: #333333;
  margin-bottom: 12px;
}
.distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 80px;
}
.distpicker-all-box select {
  width: 30%;
}
.hide-picker label:nth-of-type(1) {
  display: none;
}
.popper-customization {
  background: rgba(255, 170, 0, 0.16) !important;
  color: #ffaa00 !important;
  border: 1px solid #ffaa00 !important;
  // .popper__arrow::after {
  //   border-top-color: #747574;
  // }
}
.popper-customization[x-placement^="top"] .popper__arrow::after {
  border-top-color: #ffaa00 !important;
}
.popper-customization[x-placement^="top"] .popper__arrow {
  border-top-color: #ffaa00 !important;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
.p-file {
  .el-form-item {
    width: 100% !important;
  }
}
</style>
