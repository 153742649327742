<template>
  <el-dialog title="证件识别"
    :visible.sync="dialogVisible"
    custom-class="policy-identity-dialog"
    width="640px"
    :before-close="handleClose">
    <div>
      <div style="margin-bottom: 20px;">支持格式：jpg、png，单个文件不超过2MB。</div>
      <div class="policy-identity-dialog-box">
        <div class="policy-identity-dialog-template policy-identity-dialog-b"
          style="margin-right: 32px;">
          <el-upload class="upload-demo"
            drag
            accept=".jpg, .png"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-change="handlePreview"
            multiple
            ref="upload-box"
            :limit="1"
            :auto-upload="false">
            <img v-if="fileList && fileList.url"
              :src="fileList.url"
              style="width:100%;height:100%"
              alt />
            <div v-else>
              <img src="@/assets/images/正面.png"
                class="policy-identity-dialog-b-img"
                alt
                srcset />
              <div class="policy-identity-dialog-b-text">拖拽到此或点击上传表格</div>
            </div>
          </el-upload>
        </div>

        <div class="policy-identity-dialog-template policy-identity-dialog-b">
          <el-upload class="upload-demo"
            drag
            accept=".jpg, .png"
            ref="upload-box2"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-change="handlePreview2"
            multiple
            :limit="1"
            :auto-upload="false">
            <img v-if="fileList2 && fileList2.url"
              :src="fileList2.url"
              style="width:100%;height:100%"
              alt />
            <div v-else>
              <img src="@/assets/images/反面.png"
                class="policy-identity-dialog-b-img"
                alt
                srcset />
              <div class="policy-identity-dialog-b-text">拖拽到此或点击上传表格</div>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="policy-identity-dialog-box">
        <div class="policy-identity-dialog-text"
          style="margin-right: 32px;">身份证正面</div>
        <div class="policy-identity-dialog-text">身份证反面</div>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
        @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fileToBase64 } from "@/utils/index";
import { businessIdentityCard } from "@/api/policy";
import { OSSDirectPass } from "@/api/oss.js";
import day from 'dayjs';
export default {
  props: {
    showDialog: Boolean,
    isUpdateOss: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.fileList = null;
          this.fileList2 = null;
          this.dialogVisible = true;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: null,
      fileList2: null
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handlePreview(e) {
      this.$refs['upload-box'].clearFiles();
      this.fileList = {
        ...e,
        url: URL.createObjectURL(e.raw)
      };
    },
    handlePreview2(e) {
      this.$refs['upload-box2'].clearFiles();
      if (this.fileList && this.fileList.url) {
         this.fileList2 = {
         ...e,
         url: URL.createObjectURL(e.raw)
         };
      }else {
         this.$message.error('请选择正面照')
      }
    },
    async handleImport() {
      if ((!this.fileList || !this.fileList.url) && (!this.fileList2 || !this.fileList2.url)) {
        this.$message.error('请选择文件');
        return false;
      }
      let positiveImagBase64 = null; //正面
      let backImageBase64 = null; //反面
      if (this.fileList && this.fileList.url && (!this.fileList2 || !this.fileList2.url)) {
        let base64 = await fileToBase64(this.fileList.raw);
        positiveImagBase64 = base64.split(',')[1];
      } else if (this.fileList && this.fileList.url && this.fileList2 && this.fileList2.url) {
        let base64 = await fileToBase64(this.fileList.raw);
        let base64t = await fileToBase64(this.fileList2.raw);
        positiveImagBase64 = base64.split(',')[1];
        backImageBase64 = base64t.split(',')[1];
      } else {
        let base64 = await fileToBase64(this.fileList2.raw);
        backImageBase64 = base64.split(',')[1];
      }
      // let formData = new FormData();
      // positiveImagBase64 ? formData.append('positiveImagBase64', positiveImagBase64) : '';
      // backImageBase64 ? formData.append('backImageBase64', backImageBase64) : '';
      const loading = this.$loading({
        lock: true,
        text: '识别中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      businessIdentityCard({ positiveImagBase64, backImageBase64 }).then(async res => {
        if (res.data.birthDate) {
          res.data.birthDate = day(new Date(res.data.birthDate)).format("YYYY-MM-DD");
        }
         let emitData = { ...res.data };
         if (this.fileList && this.fileList.url && (!this.fileList2 || !this.fileList2.url) && this.isUpdateOss) {
            let type = this.fileList.name.split('.');
            emitData.identityOss = {
               fileName: this.fileList.name,
               fileType: type[type.length-1],
               url: ''
            }
            emitData.identityOss.url = await OSSDirectPass(this.fileList);
         } else if (this.fileList && this.fileList.url && this.fileList2 && this.fileList2.url && this.isUpdateOss) {
            let type = this.fileList.name.split('.');
            let type2 = this.fileList2.name.split('.');
            emitData.identityOss = {
               fileName: this.fileList.name,
               fileType: type[type.length-1],
               url: ''
            }
            emitData.identityOss2 = {
               fileName: this.fileList2.name,
               fileType: type2[type2.length-1],
               url: ''
            }
            emitData.identityOss.url = await OSSDirectPass(this.fileList);
            emitData.identityOss2.url = await OSSDirectPass(this.fileList2);
         } else if(this.isUpdateOss) {
            let type = this.fileList2.name.split('.');
            emitData.identityOss2 = {
               fileName: this.fileList2.name,
               fileType: type[type.length-1],
               url: ''
            }
            emitData.identityOss2.url = await OSSDirectPass(this.fileList2.raw);
         }
        this.$emit('identify', emitData);
        loading.close();
        this.$message.success('识别成功');
        this.dialogVisible = false;
      }).catch(err => {
        loading.close();
      });
    },
    handleDownloadTemplate() {
      const link = document.createElement('a'); // 生成一个a标签。
      link.href = '/resource/保单导入模板.xls';
      link.click();
    }
  }
};
</script>

<style lang="scss">
.policy-identity-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-box {
    display: flex;
    justify-content: center;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-text {
    width: 272px;
    text-align: center;
    font-size: 14px;
    color: #333333;
  }
  &-b {
    width: 272px;
    height: 167px;
    padding: 10px 24px;
    position: relative;
    &-img {
      display: block;
      width: 171px;
      height: 108px;
      margin: 14px auto 12px;
    }
    &-text {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      white-space: nowrap;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      height: 100%;
      .el-upload {
        width: 100%;
        height: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
      }
    }
  }
}
</style>