<template>
  <el-dialog title="关联上一年保单"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    width="85%"
    :before-close="handleClose">
    <div>
      <el-form :inline="true"
        :model="formInline"
        class="demo-form-inline">
        <el-form-item label="保单号">
          <el-input v-model="formInline.policyNo"
            placeholder="请输入保单号"></el-input>
        </el-form-item>
        <el-form-item label="投保人">
          <el-input v-model="formInline.policyHolder"
            placeholder="请输入投保人"></el-input>
        </el-form-item>
        <el-form-item label="被保险人">
          <el-input v-model="formInline.insured"
            placeholder="请输入被保险人"></el-input>
        </el-form-item>
        <el-form-item label="车架号">
          <el-input v-model="formInline.frameNo"
            placeholder="请输入车架号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
            @click="current = 1,getTableData()">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection"
          width="55"></el-table-column>
        <el-table-column type="index"
          label="序号"
          width="50"></el-table-column>
        <el-table-column prop="policyNo"
          label="保单号">
          <template slot-scope="{row}">
            <div class="el-button polecy-button el-button--text el-button--small"
              style="text-decoration:underline;padding: 0;"
              @click="handleGoPath(row)">{{ row.policyNo }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="policyHolder"
          label="投保人"></el-table-column>
        <el-table-column prop="insured"
          label="被保险人"></el-table-column>
        <el-table-column prop="insuranceTypeName"
          label="险种名称"></el-table-column>
        <el-table-column prop="insuranceCompanyName"
          label="保险公司"></el-table-column>
        <el-table-column prop="brandName" width="220px"
          label="设备品牌">
          <template
            slot-scope="{ row }">
            {{ row.isSingle !== false ? row.brandName : '多标的类型，详细查看详情' }}
            </template>
         </el-table-column>
        <el-table-column prop="equipmentType" width="220px"
          label="设备型号">
          <template
            slot-scope="{ row }">
            {{ row.isSingle !== false ? row.equipmentType : '多标的类型，详细查看详情' }}
            </template>
         </el-table-column>
        <el-table-column prop="frameNo" width="220px"
          label="车架号">
          <template
            slot-scope="{ row }">
            {{ row.isSingle !== false ? row.frameNo : '多标的类型，详细查看详情' }}
            </template>
         </el-table-column>
        <el-table-column prop="statusName"
          label="保单状态">
          <template slot-scope="{row}">
            <div :style="{ color: row.status == '1' ? '#FCA118': row.status == '2' ? '#0080FF' : '#CCCCCC' }">{{ row.statusName }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
        @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { policyList, getApprovalPolicyDetail } from "@/api/policy";
export default {
  props: {
    showDialog: Boolean,
    excludePolicyId: {
      type: [Array, String],
      default: ''
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.formInline = {
            policyNo: '',
            policyHolder: '',
            insured: '',
            frameNo: ''
          };
          this.getTableData();
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      formInline: {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      },
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      oldFormInline: {}
    };
  },
  methods: {
    getTableData(e) {
      let formInline = {};
      if (e && (e == 'current' || e == 'size')) {
        formInline = JSON.parse(JSON.stringify(this.oldFormInline));
      } else {
        this.oldFormInline = JSON.parse(JSON.stringify(this.formInline));
        formInline = JSON.parse(JSON.stringify(this.formInline));
      }
      policyList({ ...formInline, current: this.current, size: this.size, excludePolicyId: this.excludePolicyId instanceof Array ? this.excludePolicyId.toString() : this.excludePolicyId, category: this.$route.meta.policyType }).then(res => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    onReset() {
      this.current = 1;
      this.formInline = {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      };
      this.getTableData();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getTableData('current');
    },
    handleSizeChange(e) {
      this.size = e;
      this.getTableData('size');
    },
    handleImport() {
      if (!this.fileList.length) {
        this.$message.error('请选择保单');
        return false;
      }
      getApprovalPolicyDetail({
        id: this.fileList[0].id
      }).then(res => {
        let data = res.data;
        delete data.id;
        data.lastPolicyNo = data.policyNo;
        data.policyNo = '';
        this.$emit('submit', data);
      }).catch(err => {
        //   console.log(err);
        setTimeout(() => {
          this.$message.error('上传失败');
        }, 3000);
      });
    },
    handleSelectionChange(e) {
      console.log(e);
      if (e.length) {
        this.fileList = [e[e.length - 1]];
      }
      if (e.length > 1) {
        // this.$message.error('只能选择一个保单！');
        const id = e[e.length - 1].id;
        const index = this.tableData.findIndex(val => val.id == id);
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(this.tableData[index], true);
      }
    },
    handleGoPath(e) {
      this.$router.push({ path: '/routerPolicyManagement/ConstructionMachinery/policyDetails/' + e.id });
    }
  }
};
</script>

<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
}
</style>