<template>
  <el-dialog title="示例"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    width="1000px"
    :before-close="handleClose">
    <div>
      <div class="img-box"
        v-if="(typeName !== 'identity')">
        <img src="@/assets/images/policy/business-t.png"
          alt />
      </div>
      <div class="img-box2"
        v-else>
        <img src="@/assets/images/policy/identity-t.png"
          alt />
      </div>
      <div class="msg-box">
        <span>规范注意：</span>
        <i class="el-icon-check"></i>
        <span>横向拍摄</span>
        <i class="el-icon-check"></i>
        <span>边缘完整</span>
        <i class="el-icon-check"></i>
        <span>亮度均匀</span>
        <i class="el-icon-check"></i>
        <span>照片清晰</span>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
        @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { policyImport } from "@/api/getData";
export default {
  props: {
    showDialog: Boolean,
    typeName: {
      type: String,
      default: 'identity'
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: []
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handlePreview(e) {
      this.fileList = [e];
    },
    handleRemove() {
      this.fileList = [];
    },
    handleImport() {
      if (!this.fileList.length) {
        this.$message.error('请选择文件');
        return false;
      }
      console.log(this.fileList);
      let formData = new FormData();
      formData.append('multipartFile', this.fileList[0].raw);
      policyImport(formData).then(res => {
        this.$message.success('上传成功');
        this.dialogVisible = false;
        this.fileList = [];
        this.$emit('changeList');
      }).catch(err => {
        //   this.$message.error('上传失败');

        setTimeout(() => {
          this.$message.error('上传失败');
        }, 3000);
      });
      // this.dialogVisible = false;
    },
    handleDownloadTemplate() {
      const link = document.createElement('a'); // 生成一个a标签。
      link.href = '/resource/保单导入模板.xls';
      link.click();
    }
  }
};
</script>

<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  .msg-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-top: 40px;
    .el-icon-check {
      color: rgba(201, 66, 66, 1);
      margin-left: 12px;
    }
  }
  .img-box {
    width: 730px;
    height: 530px;
    margin: auto;
    padding: 10px;
    background: #eceeef;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img-box2 {
    width: 380px;
    height: 276px;
    margin: auto;
    padding: 34px 26px;
    background: #eceeef;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>